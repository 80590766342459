import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import {renderRedirect} from "../util"

import Footer from "../Components/footer";

const queryParameters = new URLSearchParams(window.location.search);
const ref = queryParameters.get("ref");
 
class MainPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: "",
      isHome: false
    };
  }

  componentWillMount() {
    const { history } = this.props;
    this.unsubscribeFromHistory = history.listen(this.handleLocationChange);
    this.handleLocationChange(history.location);

    if(ref){
      localStorage.setItem("referral", ref);
    }
  }

  componentWillUnmount() {
    if (this.unsubscribeFromHistory) this.unsubscribeFromHistory();
  }

  handleLocationChange = (location) => {
    this.setState({isHome:  location.pathname.toLowerCase() == "/signup" || location.pathname.toLowerCase() == "/" ? true : false});
  }
  renderRedirect = () => {
    renderRedirect(this.props, "/mobile");
  }
  
    render() {
        return (     
        
          <div className="col-md-6 col-lg-6 col-xl-6">

          <div className="container my-auto py-4 bg-white shadow-lg rounded">
          <div className="row mt-2">


            <div className="col-11 col-lg-11 mx-auto">


              <h4>Requirements for TRDR account opening</h4>
              <p className="text-2">Camera on the device you are using for sign up: you will be required to click a selfie</p>
              <p className="text-2">Allow access for location sharing on your device: you will be required to share it</p>
              <p className="text-2">Aadhaar number + mobile number linked to it: you will receive an OTP</p>
              <p className="text-2">Soft copy (image/pdf files) you will be asked to upload:
                <ul>
                  <li>PAN Card</li>
                  <li>Cancelled Bank Check / latest bank statement (this is the primary bank account linked to TRDR that you will be using to transfer/withdraw funds)</li>
                  <li>Your signature on a white paper</li>
                </ul>
              </p>
              <div className="form-group">              
              <button type="button"  onClick={this.renderRedirect} className="btn btn-primary btn-block text-uppercase my-3">CONTINUE</button>
            </div>
            </div>
            
          </div>
        </div><Footer /></div>
            )
    }
}
export default withRouter(MainPage);