import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { renderRedirect } from "../util";
import {fetchSignApplication, updateSignApplication} from "./BasicProfile/api"
import Footer from "../Components/footer";
import './AddNominee/SwitchStyles.css'

class AdditionalDetails extends Component {
  renderRedirect = () => {
    if(this.state.isAddNominee){
      renderRedirect(this.props, "/add-nominee");
    }else{
      renderRedirect(this.props, "/bank-cheque");
    }
  };

  constructor(props) {
    super(props);
    this.state = {
      fathersName: null,
      mothersName: null,
      occupationType: null,
      annualIncome: null,
      isValidated: false,
      politicallyExposed: null,
      isAddNominee:false,
      netWorthDate:null,
      netWorth:null,
      errors: {
        fathersName: "",
        mothersName: "",
        occupationType: "",
        annualIncome: "",
        politicallyExposed: "",
      },
    };
  }

  validateForm = () => {
    if (
      this.state.fathersName!="" &&
      this.state.mothersName!="" &&
      this.state.occupationType!="" &&
      this.state.annualIncome!="" &&
      this.state.politicallyExposed!=""
    ) {
      this.setState({["isValidated"]: true});
      return true;
    } else {
      this.setState({["isValidated"]: false});
      return false;
    }
  };
  async componentDidMount() {
    let profileData = await fetchSignApplication();
    if(profileData && profileData.data && profileData.data.fetch_signup_application){
      let data = profileData.data.fetch_signup_application;
      this.setState({["fathersName"]: data.fathersName});
      this.setState({["mothersName"]: data.mothersName});
      this.setState({["occupationType"]: data.occupationType});
      this.setState({["annualIncome"]: decodeURIComponent(data.annualIncome)});
      this.setState({["politicallyExposed"]: data.isPoliticalyExposed});
      this.setState({ ["netWorthDate"]: data.net_worth_date ? moment(data.net_worth_date).format("YYYY-MM-DD"): null });
      this.setState({['netWorth']:data.net_worth})
    }
    this.validateForm();
  }


  handleChange = (event) => {
    event.preventDefault();
    const { id, value } = event.target;
    let errors = this.state.errors;
    switch (id) {
      case "fathersName":
        if (value.length < 1) {
          //  errors.fathersName = "Father's name cannot be empty";
        } else {
          errors.fathersName = "";
          this.setState({["fathersName"]: value});
        }
        break;
      case "mothersName":
        if (value.length < 1) {
         // errors.mothersName = "Mother's name cannot be empty";
        } else {
          errors.mothersName = "";
          this.setState({["mothersName"]: value});
        }
        break;
      case "occupationType":
        if (value.length < 1) {
          //errors.occupationType = "Occupation Type cannot be empty";
        } else {
          errors.occupationType = "";
          this.setState({["occupationType"]: value});
        }
        break;
      case "annualIncome":
        if (value.length < 1) {
         // errors.annualIncome = "Annual Income  cannot be empty";
        } else {
          errors.annualIncome = "";
          this.setState({["annualIncome"]: value});
        }
        break;
      case "politicallyExposed":
        if (value.length < 1) {
          //errors.politicallyExposed = "Politically Exposed   cannot be empty";
        } else {
          errors.politicallyExposed = "";
          this.setState({["politicallyExposed"]: value});
        }
        break;
      default:
        break;
    }
    this.setState({ errors, [id]: value });
    window.setTimeout(function () {
      this.validateForm();
    }.bind(this), 600);
  };

  handleSubmit = async(event) => {    
    let reqParam = {
      fathersName: this.state.fathersName,
      mothersName: this.state.mothersName,
      occupationType: this.state.occupationType,
      annualIncome: this.state.annualIncome,
      isPoliticalyExposed: this.state.politicallyExposed,
      net_worth_date: this.state.netWorthDate
            ? moment(this.state.netWorthDate).format("DD-MM-YYYY")
            : this.state.netWorthDate,
      net_worth:this.state.netWorth
    };
    let updateProfile = await updateSignApplication(reqParam);
    this.renderRedirect();   
  };

  handleNomineeSwitch=(e)=>{
    this.setState({isAddNominee:e.target.checked})
  }

  render() {
    const { errors } = this.state;
    return (      <div className="col-md-6 col-lg-5 col-xl-4">

      <div className="container my-auto py-4 bg-white shadow-lg rounded">
        <div className="row mt-2">
          <div className="col-11 col-lg-11 mx-auto">
            <h1>Additional Details</h1>
            <form id="registerForm" className="form-border" onChange={this.hand}>
              <div className="form-group icon-group">
                <input
                  type="text"
                  className="form-control floating-label-field floating-label-field--s1"
                  id="fathersName"
                  required
                  placeholder="Father's Name"
                  onChange={this.handleChange}
                  onBlur={this.handleChange}
                  value={this.state.fathersName}
                /> <label className="floating-label">Father's Name</label>
                <span className="icon-inside text-primary">
                  <i className="fas fa-user"></i>
                </span>
                {errors.fathersName.length > 0 && (
                  <span className="error">{errors.fathersName}</span>
                )}
              </div>
              <div className="form-group icon-group">
                <input
                  type="text"
                  className="form-control floating-label-field floating-label-field--s1"
                  id="mothersName"
                  required
                  placeholder="Mother's Name"
                  onChange={this.handleChange}
                  onBlur={this.handleChange}
                  value={this.state.mothersName}
                /> <label className="floating-label">Mother's Name</label>
                <span className="icon-inside text-primary">
                  <i className="fas fa-user"></i>
                </span>
                {errors.mothersName.length > 0 && (
                  <span className="error">{errors.mothersName}</span>
                )}
              </div>
              <div className="form-group icon-group"><select
                  id="occupationType"
                  className="form-control floating-label-field floating-label-field--s1"
                  onChange={this.handleChange}
                  onBlur={this.handleChange}
                  noValidate
                  value={this.state.occupationType}
                >
                  <option value="">Select an option</option>

                  <option value="pvt_sector">Private Sector</option>
                  <option value="gov_service">Government Service</option>
                  <option value="pub_sector">Public Sector</option>
                  <option value="agriculturist">Agriculturalist</option>
                  <option value="professional">Professional</option>
                  <option value="business">Business</option>
                  <option value="housewife">Housewife</option>
                  <option value="retired">Retired</option>
                  <option value="student">Student</option>
                  <option value="others">Others</option>
                </select> <label className="floating-label">Occuption Type</label>
                <span className="icon-inside text-primary">
                  <i className="fas fa-user-md"></i>
                </span>
                {errors.occupationType.length > 0 && (
                  <span className="error">{errors.occupationType}</span>
                )}
              </div>
              <div className="form-group icon-group">
                <select
                  id="annualIncome"
                  className="form-control floating-label-field floating-label-field--s1"
                  onChange={this.handleChange}
                  onBlur={this.handleChange}
                  noValidate
                  value={this.state.annualIncome}
                > 
                  <option value="">Select an option</option>
                  <option value="less_than_1_lakh">Below 1 Lakh</option>
                  <option value="one_to_5_lakh">1 Lakh - 5 Lakh</option>
                  <option value="five_to_10_lakh">5 Lakh to 10 Lakh</option>
                  <option value="ten_to_25_lakh">10 Lakh to 25 Lakh</option>
                  <option value="greater_than_25_lakh">Above 25 Lakh</option>
                </select><label className="floating-label">Annual Income</label>
                <span className="icon-inside text-primary">
                  <i className="fa fa-rupee-sign"></i>
                </span> 
                {errors.annualIncome.length > 0 && (
                  <span className="error">{errors.annualIncome}</span>
                )}
              </div>
              <div className="form-group icon-group">
                
                  <select
                    id="politicallyExposed"
                    className="form-control floating-label-field floating-label-field--s1"
                    noValidate
                    value={this.state.politicallyExposed}
                    onChange={this.handleChange}
                  onBlur={this.handleChange}
                  >
                    <option value="">Select an option</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                  <label className="floating-label">Politically Exposed</label>
                <span className="icon-inside text-primary">
                  <i className="fas fa-user-shield"></i>
                </span>
                {errors.politicallyExposed.length > 0 && (
                  <span className="error">{errors.politicallyExposed}</span>
                )}
              </div>

              <div className="form-group icon-group">
                <input
                  type="date"
                  className="form-control floating-label-field floating-label-field--s1"
                  id="netWorthDate"
                  required
                  placeholder="Net Worth Date"
                  onChange={this.handleChange}
                  onBlur={this.handleChange}
                  value={this.state.netWorthDate}
                  max={moment().format("YYYY-MM-DD")}
                  onKeyDown={(e) => e.preventDefault()}
                /> <label className="floating-label">Net Worth Date</label>
                <span className="icon-inside text-primary">
                  <i className="fas fa-calendar"></i>
                </span>
              </div>

              <div className="form-group icon-group">
                <input
                  type="number"
                  className="form-control floating-label-field floating-label-field--s1"
                  id="netWorth"
                  required
                  placeholder="Net Worth"
                  onChange={this.handleChange}
                  onBlur={this.handleChange}
                  value={this.state.netWorth}
                  min={0}
                /> <label className="floating-label">Net Worth</label>
                <span className="icon-inside text-primary">
                  <i className="fas fa-briefcase"></i>
                </span>
              </div>

              <div>
                <span className="icon-inside text-primary">
                  {/* <i className="fas fa-user-plus"></i> */}
                  <label>Do you wish to add nominee?</label>
                </span>
              </div>
                           
              <div className="form-group icon-group">
                   <label className="switch">
                        <input className="switch-input" type="checkbox" checked={this.state.isAddNominee} onChange={this.handleNomineeSwitch} />
                        <span className="switch-label" data-on="Yes" data-off="No"></span>
                        <span className="switch-handle"></span>
                   </label>
              </div>

              <button
                className="btn btn-primary btn-block text-uppercase mt-4"
                type="button"
                onClick={this.handleSubmit}
                disabled={!this.state.isValidated}
              >
                Next
              </button>
            </form>
          </div>
        </div>
      </div><Footer /></div>
    );
  }
}
export default withRouter(AdditionalDetails);