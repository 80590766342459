import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { renderRedirect } from "../../util"
import { updateSignApplication, fetchSignApplication, sendEmail } from "./api"
import { spinnerService } from "../../Components/spinner.service";
import { startSignUpFlow, authenitcateUser, startSignUpWorkFlow } from "../Mobile/api";
import Footer from "../../Components/footer";

class BasicProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      businessName: "",  //Reference Busineness name
      referralCode: "",  //Refferal Code
      formErrors: {
        firstName: "", 
        lastName: "", 
        email: "",
        // businessName: ""
      },
      firstNameValid: false,
      lastNameValid: false,
      emailValid: false,
      refferalBusinessName: ''
      // businessNameValid: false
    };
  }

  async componentDidMount() {
    let profileData = await fetchSignApplication();
    if (profileData && profileData.data && profileData.data.fetch_signup_application) {
      let data = profileData.data.fetch_signup_application;
      this.setState({ ["firstName"]: data.firstName });
      this.setState({ ["lastName"]: data.lastName });
      this.setState({ ["email"]: data.emailAddress });
      this.setState({ ["businessName"]: data.businessName });
      this.setState({ ["referralCode"]: data.referralCode });
      this.setState({ ["refferalBusinessName"]: data.businessName && `${data.businessName.toUpperCase()} : ` });

      //validate
      this.validateField("firstName", data.firstName);
      this.validateField("lastName", data.lastName);
      this.validateField("email", data.emailAddress);
      // this.validateField("businessName", data.businessName);
    }
  }

  handleUserInput(e) {
    const name = e.target.name;
    let value = e.target.value;
    if (name === 'referralCode' && this.state.refferalBusinessName) {
      value = this.state.refferalBusinessName && value.slice(this.state.refferalBusinessName.length)
    }
    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  }

  _updateBasicProfile = async () => {
    spinnerService.show("mainSpinner", "Please wait...");

    let reqParam = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      emailAddress: this.state.email,
      businessName: this.state.businessName ? this.state.businessName : '',
      referralCode: this.state.referralCode ? this.state.referralCode : ''
    };

    localStorage.setItem("email", this.state.email);

    let auth = await authenitcateUser();
    if (auth && auth.data && auth.data.authenticate_user) {
      let user = auth.data.authenticate_user;
      if (user.status == true && user.approval_status == "approved") {
        await startSignUpFlow();
        await startSignUpWorkFlow();
      }
    }

    let updateProfile = await updateSignApplication(reqParam);

    if (updateProfile) {
      await sendEmail(reqParam);
      spinnerService.hide("mainSpinner");
      this._renderRedirect();
    }
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let firstNameValid = this.state.firstNameValid;
    let emailValid = this.state.emailValid;
    let lastNameValid = this.state.lastNameValid;
    // let businessNameValid = this.state.businessNameValid;

    switch (fieldName) {
      case "firstName":
        firstNameValid = value && value.length > 0;
        fieldValidationErrors.firstName = firstNameValid ? "" : " is invalid";
        break;
      case "lastName":
        lastNameValid = value && value.length > 0;
        fieldValidationErrors.lastName = lastNameValid ? "" : " is invalid";
        break;
      case "email":
        emailValid = value && value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        fieldValidationErrors.email = emailValid ? "" : " is invalid";
        break;
      // case "businessName":
      //   businessNameValid = value && value.length > 0;
      //   fieldValidationErrors.businessName = businessNameValid ? "" : " is invalid";
      //   break;
      default:
        break;
    }
    this.setState(
      {
        formErrors: fieldValidationErrors,
        firstNameValid: firstNameValid,
        lastNameValid: lastNameValid,
        emailValid: emailValid,
        // businessNameValid: businessNameValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid:
        this.state.firstNameValid &&
        this.state.lastNameValid &&
        this.state.emailValid,
    });
  }

  errorClass(error) {
    return error.length === 0 ? "" : "is-invalid";
  }

  _renderRedirect = () => {
    renderRedirect(this.props, "/email-otp");
  };
  render() {
    return (
      <div className="col-md-6 col-lg-5 col-xl-4">
        <div className="container my-auto py-4 bg-white shadow-lg rounded">
          <div className="row mt-2">
            <div className="col-11 col-lg-11 mx-auto">
              <h1>Basic Details</h1>

              <form id="registerForm" className="form-border" method="post">


                <div className="form-group icon-group floating-label-wrap">
                  <input
                    type="text"
                    className="form-control floating-label-field floating-label-field--s1"
                    name="firstName"
                    value={this.state.firstName}
                    onChange={(event) => this.handleUserInput(event)}
                    required
                    placeholder="First Name"
                  />
                  <label class="floating-label">First Name</label>
                  <span className="icon-inside text-primary">
                    <i className="fas fa-user"></i>
                  </span>{" "}
                </div>
                <div className="form-group icon-group floating-label-wrap">
                  <input
                    type="text"
                    className="form-control floating-label-field floating-label-field--s1"
                    name="lastName"
                    required
                    value={this.state.lastName}
                    onChange={(event) => this.handleUserInput(event)}
                    placeholder="Last Name"
                  />
                  <label class="floating-label">Last Name</label>

                  <span className="icon-inside text-primary">
                    <i className="fas fa-user"></i>
                  </span>{" "}
                </div>
                <div className="form-group icon-group floating-label-wrap">
                  <input
                    type="email"
                    className="form-control floating-label-field floating-label-field--s1"
                    name="email"
                    required
                    value={this.state.email}
                    onChange={(event) => this.handleUserInput(event)}
                    placeholder="Email Address"
                  />
                  <label class="floating-label">Email Address</label>

                  <span className="icon-inside text-primary">
                    <i className="fas fa-envelope"></i>
                  </span>{" "}
                </div>
                <p className="text-2 text-muted">
                  We will send an OTP to the above email
                </p>
                <div className="form-group icon-group floating-label-wrap">
                  <input
                    type="text"
                    className="form-control floating-label-field floating-label-field--s1"
                    name="referralCode"
                    value={this.state.businessName ? `${this.state.businessName.toUpperCase()} : ${this.state.referralCode}` : this.state.referralCode}
                    onChange={(event) => this.handleUserInput(event)}
                    placeholder="Reference"
                  />
                  <label class="floating-label">Reference</label>

                  <span className="icon-inside text-primary">
                    <i className="fas fa-landmark"></i>
                  </span>{" "}
                </div>
                <button
                  disabled={!this.state.formValid}
                  className="btn btn-primary btn-block text-uppercase mt-4"
                  type="button"
                  onClick={this._updateBasicProfile}
                >
                  Verify Email
                </button>
              </form>
            </div>
          </div>
        </div> <Footer /></div>
    );
  }
}
export default withRouter(BasicProfile);
