import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { renderRedirect } from "../../util";
import { verifyEmail } from "./api";
import { spinnerService } from "../../Components/spinner.service";
import $ from 'jquery';
import Footer from "../../Components/footer";

class VerifyEmailOTP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailOTP: ["", "", "", "", "", ""],
      errorMessage: "",
      email: localStorage.getItem("email")
    };
  }


  async componentDidMount() {
    $("#otp-screen .form-control").keyup(function() {  
      if (this.value.length == 0) {
         $(this).blur().parent().prev().children('.form-control').focus();
      }
      else if (this.value.length == this.maxLength) {
         $(this).blur().parent().next().children('.form-control').focus();
      }
      });
      this.autoFocus();

  }

  
  autoFocus = () => {
    $("#first").focus();
  }
  
  handleUserInput(event, position) {
    const { emailOTP } = this.state;
    emailOTP[position] = event.target.value;
    this.setState({ emailOTP: emailOTP });
  }

  renderRedirect = () => {};

  verifyEmailOTP = async () => {
    spinnerService.show("mainSpinner", "Please wait...");
    try {
      const verifyStatus = await verifyEmail(
        localStorage.getItem("email"),
        this.state.emailOTP.join("")
      );
      if (verifyStatus && verifyStatus.data.verify_email.status) {

        
  

        renderRedirect(this.props, "/fees");
      } else if (verifyStatus && !verifyStatus.data.verify_email.status) {
        this.setState({ errorMessage: verifyStatus.data.verify_email.message });
      }
      spinnerService.hide("mainSpinner");
    } catch (e) {
      this.setState({ errorMessage: "Some error occured" });
      spinnerService.hide("mainSpinner");
    }
  };
  render() {
    return (
      <div className="col-md-6 col-lg-5 col-xl-4">

      <div className="container my-auto py-4 bg-white shadow-lg rounded">
        <div className="row mt-2">
          <div className="col-11 col-lg-11 mx-auto">
            <h1>Confirm Email OTP</h1>
            <p className="text-center">
              <img
                className="img-fluid"
                src="../images/otp-icon.png"
                alt="verification"
              />
            </p>
            <p className="text-muted text-2 text-center">
              Please enter the OTP (one time password) to verify your account. A
              Code has been sent to{" "}
              <span className="text-dark text-4">{this.state.email}</span>
              {this.state.errorMessage ? (
                <span style={{ display: "block" }} className="alert alert-warning alert-dismissible fade show text-left">
                  {this.state.errorMessage}
                </span>
              ) : (
                ""
              )}
            </p>
            <form id="otp-screen" className="form-border" method="post">
              <div className="form-row">
                <div className="col form-group">
                  <input
                   id="first"
                   autofocus
                   inputmode="decimal" pattern="[0-9]*"
                    value={this.state.emailOTP[0]}
                    onChange={(event) => this.handleUserInput(event, 0)}
                    className="form-control text-center text-6 px-0 py-2"
                    maxLength="1"
                    required
                    autoComplete="off"
                  />
                </div>
                <div className="col form-group">
                  <input
                   inputmode="decimal" pattern="[0-9]*"
                    value={this.state.emailOTP[1]}
                    onChange={(event) => this.handleUserInput(event, 1)}
                    className="form-control text-center text-6 px-0 py-2"
                    maxLength="1"
                    required
                    autoComplete="off"
                  />
                </div>
                <div className="col form-group">
                  <input
                   
                   inputmode="decimal" pattern="[0-9]*"
                    value={this.state.emailOTP[2]}
                    onChange={(event) => this.handleUserInput(event, 2)}
                    className="form-control text-center text-6 px-0 py-2"
                    maxLength="1"
                    required
                    autoComplete="off"
                  />
                </div>
                <div className="col form-group">
                  <input
                 
                 inputmode="decimal" pattern="[0-9]*"
                    value={this.state.emailOTP[3]}
                    onChange={(event) => this.handleUserInput(event, 3)}
                    className="form-control text-center text-6 px-0 py-2"
                    maxLength="1"
                    required
                    autoComplete="off"
                  />
                </div>
                <div className="col form-group">
                  <input
                    inputmode="decimal" pattern="[0-9]*"
                    value={this.state.emailOTP[4]}
                    onChange={(event) => this.handleUserInput(event, 4)}
                    className="form-control text-center text-6 px-0 py-2"
                    maxLength="1"
                    required
                    autoComplete="off"
                  />
                </div>
                <div className="col form-group">
                  <input
              
              inputmode="decimal" pattern="[0-9]*"
                    value={this.state.emailOTP[5]}
                    onChange={(event) => this.handleUserInput(event, 5)}
                    className="form-control text-center text-6 px-0 py-2"
                    maxLength="1"
                    required
                    autoComplete="off"
                  />
                </div>
              </div>
              <button
                onClick={this.verifyEmailOTP}
                className="btn btn-primary btn-block my-3"
                type="button"
              >
                CONFIRM
              </button>
            </form>
          </div>
        </div>
      </div><Footer /></div>
    );
  }
}
export default VerifyEmailOTP;
