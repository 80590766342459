import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { renderRedirect, workFlowSteps } from "../../util";
import { getOTP } from "./api";
import $ from "jquery";
import { spinnerService } from "../../Components/spinner.service";
import { query, mutation } from "../../api";
import Footer from "../../Components/footer";

class MobileOTP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobile: "",
      iaccept: "",
      formErrors: { mobile: "", iaccept: "" },
      mobileValid: false,
      iacceptValid: false,
    };
  }

  async componentDidMount() {
    $("#otp-screen .form-control").keyup(function () {
      if (this.value.length == 0) {
        $(this).blur().parent().prev().children(".form-control").focus();
      } else if (this.value.length == this.maxLength) {
        $(this).blur().parent().next().children(".form-control").focus();
      }
    });
    this.autoFocus();
  }

  autoFocus = () => {
    $("#first").focus();
  };

  async componentDidMount() {}

  _renderRedirect = (url = "") => {
    renderRedirect(this.props, url == "" ? "/mobile-otp" : "/" + url);
  };

  _getOTP = async () => {
    spinnerService.show("mainSpinner", "Please wait...");

    let otp = await getOTP(this.state.mobile).catch((res) => {
      alert(res);
    });
    localStorage.setItem("mobile", this.state.mobile);

    if (otp?.data?.get_session_otp?.status) {
      localStorage.setItem("session_id", otp.data.get_session_otp?.session_id);
      spinnerService.hide("mainSpinner");
      this._renderRedirect();
    }
  };

  handleUserInput(e) {
    const name = e.target.name;
    let value = e.target.value;
    if (name == "iaccept") {
      value = e.target.checked;
    }

    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  }

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let mobileValid = this.state.mobileValid;
    let iacceptValid = this.state.iacceptValid;

    switch (fieldName) {
      case "mobile":
        mobileValid =
          value.length == 10 && Number.isInteger(parseInt(value)) == true;
        fieldValidationErrors.mobile = mobileValid ? "" : " is invalid";
        break;
      case "iaccept":
        iacceptValid = value;
        fieldValidationErrors.iaccept = iacceptValid ? "" : " is invalid";
        break;
      default:
        break;
    }
    this.setState(
      {
        formErrors: fieldValidationErrors,
        mobileValid: mobileValid,
        iacceptValid: iacceptValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid: this.state.mobileValid && this.state.iacceptValid,
    });
  }

  errorClass(error) {
    return error.length === 0 ? "" : "is-invalid";
  }

  render() {
    return (
      <div className="col-md-6 col-lg-5 col-xl-4">
        <div className="container my-auto py-4 bg-white shadow-lg rounded">
          <div className="row mt-2">
            <div className="col-11 col-lg-11 mx-auto">
              <h1>Mobile Verification</h1>

              <div className="contentArea">
                <p className="text-2 text-muted">
                  Please enter your mobile number
                </p>
              </div>
              <div id="loginForm" className="form-border" method="post">
                <div className="form-group icon-group  icon-group floating-label-wrap">
                  <input
                    inputMode="decimal"
                    pattern="[0-9]*"
                    maxLength="10"
                    autoComplete="off"
                    className={`form-control   floating-label-field floating-label-field--s1`}
                    name="mobile"
                    required
                    placeholder="Mobile Number"
                    value={this.state.mobile}
                    onChange={(event) => this.handleUserInput(event)}
                    autoFocus="true"
                  />
                  <span className="icon-inside text-primary phone-index">
                    {" "}
                    +91
                  </span>{" "}
                </div>
                <div className="form-group icon-group">
                  <input
                    className="form-check-input phone-input "
                    name="iaccept"
                    type="checkbox"
                    id="iaccept"
                    onChange={(event) => this.handleUserInput(event)}
                  />
                  <label
                    className="text-2"
                    className="form-check-label text-muted accept-input"
                    for="flexCheckChecked"
                  >
                    I accept the{" "}
                    <a
                      className="btn-link   "
                      href={process.env.REACT_APP_T_C_LINK}
                    >
                      T&C
                    </a>{" "}
                    and{" "}
                    <a
                      className="btn-link"
                      href={process.env.REACT_APP_PRIVACY}
                    >
                      Privacy Policy
                    </a>{" "}
                    of TRDR{" "}
                  </label>
                </div>
              </div>
              <div className="form-group">
                <button
                  type="button"
                  disabled={!this.state.formValid}
                  onClick={this._getOTP}
                  className="btn btn-primary btn-block text-uppercase my-4"
                  type="button"
                >
                  VERIFY MOBILE
                </button>
              </div>
              <p className="text-center text-muted">
                Already have an account?
                <a
                  className="btn-link text-2"
                  href={process.env.REACT_APP_BASE_URL_SIGN_IN}
                >
                  {" "}
                  Sign In{" "}
                </a>
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default withRouter(MobileOTP);
