import React, { Component, useState } from "react";
import { withRouter } from "react-router-dom";

import { renderRedirect } from "../util";
import DatePicker from "react-datepicker";
import {fetchSignApplication, updateSignApplication, sendEmail} from "./BasicProfile/api"
import $ from 'jquery';
import "react-datepicker/dist/react-datepicker.css";
import { spinnerService } from "../Components/spinner.service";
import Footer from "../Components/footer";

class PersonalDetails extends Component {
  renderRedirect = () => {
    renderRedirect(this.props, "/address");
  };

  constructor(props) {
    super(props);
    this.state = {
      dateOfBirth:  new Date(),
      fullName: null,
      panNumber: null,
      maritalStatus: null,
      gender: null,
      isValidated: false,
      errors: {
        fullName: "",
        dateOfBirth: "",
        panNumber: "",
        maritalStatus: "",
        gender: "",
      },
    };
  }
  validateForm = () => {
    //console.log(this.state.fullName +"-dob-"+this.state.dateOfBirth+"--pan-"+this.state.panNumber+"--mas-"+this.state.maritalStatus+"-gen--"+this.state.gender);
    if (
      this.state.fullName &&
      this.state.panNumber &&
      this.state.maritalStatus &&
      this.state.gender
      // && this.state.dateOfBirth
    ) {
      this.setState({ ["isValidated"]: true });
      console.log(this.state.isValidated);
      return true;
    } else {
      this.setState({ ["isValidated"]: false });
      console.log(this.state.isValidated);
      return false;
    }
  };

  async componentDidMount() {


    let profileData = await fetchSignApplication();
    if(profileData && profileData.data && profileData.data.fetch_signup_application){
      let data = profileData.data.fetch_signup_application;
      this.setState({["fullName"]: data.fullName});
      this.setState({["panNumber"]: data.pan});
      this.setState({["maritalStatus"]: data.martialStatus});
      this.setState({["gender"]: data.gender});
      console.log(data.dob);
      let date = decodeURIComponent( data.dob );
      // let dateArr = date.split("%2F");
      // let dateObj = new Date(dateArr[2], dateArr[1], dateArr[0]); 
      this.setState({["dateOfBirth"]: date});

      this.validateForm();
    }
  }
  
  handleChange = (event) => {
    let errors = this.state.errors;
    if (event instanceof Date) {
      console.log("INside event" + event);
      if (event.length < 1) {
        errors.dateOfBirth = "Please select date of birth";
        this.setState({ ["dateOfBirth"]: null });
      } else {
        errors.dateOfBirth = "";
        this.setState({ ["dateOfBirth"]: event });
        console.log("State DOB Value-" + this.state.dateOfBirth);
      }
    } else if(event && event.target) {
      const { id, value } = event.target;
      switch (id) {
        case "fullName":
          if (value.length < 1) {
            //errors.fullName = "Full Name must be at least 5 characters long!";
            this.setState({ ["fullName"]: null });
          } else {
            errors.fullName = "";
            this.setState({ ["fullName"]: value });
          }
          break;
        case "panNumber":
          if (value.length < 1) {
            //errors.panNumber = "Pan number must be at least 8 characters long!";
            this.setState({ ["panNumber"]: null });
          } else {
            errors.panNumber = "";
            this.setState({ ["panNumber"]: value });
          }
          break;
        case "maritalStatus":
          if (value.length < 1) {
            //errors.maritalStatus = "Please choose a gender";
            this.setState({ ["maritalStatus"]: null });
          } else {
            errors.maritalStatus = "";
            this.setState({ ["maritalStatus"]: value });
          }
          break;
        case "gender":
          if (value.length < 1) {
           // errors.gender = "Gender must be at least 5 characters long!";
            this.setState({ ["gender"]: null });
          } else {
            errors.gender = "";
            this.setState({ ["gender"]: value });
          }
          break;
        default:
          break;
      }
    }
    this.setState({ errors });
    

    window.setTimeout(function () {
      this.validateForm();
    }.bind(this), 600);

    
  
  };

  handleSubmit = async(event) => {
    spinnerService.show("mainSpinner", "Please wait...");

    let reqParam = {
      //dob: this.state.dateOfBirth.getDate()+"/"+this.state.dateOfBirth.getMonth()+"/"+this.state.dateOfBirth.getFullYear(),
      fullName: this.state.fullName,
      pan: this.state.panNumber,
      martialStatus: this.state.maritalStatus,
      gender: this.state.gender,
    };
    let updateProfile = await updateSignApplication(reqParam);
    if(updateProfile){
      spinnerService.hide("mainSpinner");
      this.renderRedirect();
    }
    event.preventDefault();
  };

  render() {
    const { errors } = this.state;
    return (      <div className="col-md-6 col-lg-5 col-xl-4">

      <div className="container my-auto py-4 bg-white shadow-lg rounded">
        <div className="row mt-2">
          <div className="col-11 col-lg-11 mx-auto">
            <h1>Personal Details</h1>
            <form id="registerForm" className="form-border">
              <div className="form-group icon-group floating-label-wrap">
                <input
                  type="text"
                  className="form-control  floating-label-field floating-label-field--s1"
                  id="fullName"
                  required
                  value={this.state.fullName}
                  placeholder="Full Name"
                  onChange={this.handleChange}
                  onKeyUp={this.handleChange}
                  noValidate
                />
                                <label class="floating-label">Full Name</label>

                <span className="icon-inside text-primary">
                  <i className="fas fa-user"></i>
                </span>
                {errors.fullName.length > 0 && (
                  <span className="error">{errors.fullName}</span>
                )}
              </div>

              <div className="form-group icon-group floating-label-wrap">
                <select
                  id="gender"
                  className="form-control  floating-label-field floating-label-field--s1"
                  onChange={this.handleChange}
                  onKeyUp={this.handleChange}
                  noValidate
                  value={this.state.gender}
                >                    <option value="">Select an option</option>

                  <option value="M">Male</option>
                  <option value="F">Female</option>
                  <option value="T">Transgender</option>
                </select>
                <label class="floating-label">Gender</label>

                <span className="icon-inside text-primary">
                  <i className="fas fa-venus-mars"></i>
                </span>
                {errors.gender.length > 0 && (
                  <span className="error">{errors.gender}</span>
                )}
              </div>
              <div className="form-group icon-group floating-label-wrap date-of-birth">
                <input
                  readOnly="true"
                  type="text"
                  className="form-control  floating-label-field floating-label-field--s1"
                  required
                  onChange={this.handleChange}
                  onKeyUp={this.handleChange}
                  noValidate
                  value={this.state.dateOfBirth}
                />
                                <label class="floating-label">Date of birth</label>

                <span className="icon-inside text-primary">
                  <i className="fas fa-calendar"></i>
                </span>
                {errors.fullName.length > 0 && (
                  <span className="error">{errors.fullName}</span>
                )}
              </div>

         
              <div className="form-group icon-group floating-label-wrap">
                <input
                  type="text"
                  className="form-control  floating-label-field floating-label-field--s1"
                  id="panNumber"
                  required
                  placeholder="PAN Number"
                  onChange={this.handleChange}
                  onKeyUp={this.handleChange}
                  noValidate
                  value={this.state.panNumber}
                />
                                <label class="floating-label">PAN Number</label>

                <span className="icon-inside text-primary">
                  <i className="fas fa-file"></i>
                </span>
                {errors.panNumber.length > 0 && (
                  <span className="error">{errors.panNumber}</span>
                )}
              </div>
              <div className="form-group icon-group floating-label-wrap">
                <select
                  id="maritalStatus"
                  className="form-control  floating-label-field floating-label-field--s1"
                  onChange={this.handleChange}
                  onKeyUp={this.handleChange}
                  noValidate
                  value={this.state.maritalStatus}
                >                    <option value="">Select an option</option>

                  <option value="Single">Single</option>
                  <option value="Married">Married</option>
                  <option value="Other">Other</option>
                </select>
                <label class="floating-label">Martial Status</label>
                <span className="icon-inside text-primary">
                  <i className="fas fa-users"></i>
                </span>
                {errors.maritalStatus.length > 0 && (
                  <span className="error">{errors.maritalStatus}</span>
                )}
              </div>

              <button
                className="btn btn-primary btn-block text-uppercase mt-4"
                type="button"
                onClick={this.handleSubmit}
                                disabled={!this.state.isValidated}

              >
                NEXT
              </button>
            </form>
          </div>
        </div>
      </div><Footer /></div>
    );
  }
}
export default withRouter(PersonalDetails);