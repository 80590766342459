import React, { Component } from 'react';
import Footer from "../Components/footer";

class Complete extends Component {
    render() {
        return (      <div className="col-md-6 col-lg-5 col-xl-4">

          <div className="container my-auto py-4 bg-white shadow-lg rounded">
                <div className="row mt-2">
                  <div className="col-11 col-lg-11 mx-auto">
                    <h1>Complete!</h1>
                    <p className="text-muted text-2 text-left">
                      <p>Your TRDR sign up process is complete.</p>
                      <p className="alert alert-success alert-dismissible fade show text-left">Within the next 24-48 hrs, we will update you about your demat + trading account along with the TRDR app login details</p>  
                   
                    </p>
                  </div>
                </div>
              </div><Footer /></div>
              )
    }
}
export default Complete;