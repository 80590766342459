import React, { Component } from 'react';
 
class Footer extends Component {
    render() {
        return (
		<div className="text-2 font-weight-300 text-center mt-4 mb-0">            
            <p >Copyright © 2021 <a className=" font-weight-600" href="#">TRDR</a> Fintech Pvt Ltd.</p>
        </div>
)
    }
}
export default Footer;