import React, { Component } from 'react';
import { withRouter } from "react-router-dom";

import {renderRedirect} from "../util"
import {fetchSignApplication, updateSignApplication, getIfscCode} from "./BasicProfile/api"
import {pennyDrop} from "./Pay/api"
import { spinnerService } from "../Components/spinner.service";
import Footer from "../Components/footer";
import { data } from 'jquery';


 
class LinkBankAccount extends Component {
 

    constructor(props) {
      super(props);
      this.state = {
        
        fullName: null,
        ifsccode: null,
        accountType: "",
        accountNo: null,
        bankName: null,
        confirmAccountNo: null,
        isValidated: false,
        pennyDropFails: false,
        pennyMessage: "",
        errors: {
          fullName: "",
          ifsccode: "",
          accountType: "",
          accountNo: "",
          confirmAccountNo:""
        },
      };
      this.handleChange = this.handleChange.bind(this);
    }

    renderRedirect = () => {
      renderRedirect(this.props, "/signature");
    }

    async componentDidMount() {
   
      let profileData = await fetchSignApplication();
      if(profileData && profileData.data && profileData.data.fetch_signup_application){
        let data = profileData.data.fetch_signup_application;
        let bankName="";
        if(data && data.ifsc){
          let bankDetails =await getIfscCode(data.ifsc);
          bankName = bankDetails.BANK.toLowerCase() + ", " + bankDetails.BRANCH.toLowerCase();
        }
        this.setState({["fullName"]: data.fullName});
        this.setState({["ifsccode"]: data.ifsc});
        this.setState({["bankName"]:bankName});
        this.setState({["accountType"]: data.account_type});
        this.setState({["accountNo"]: data.account_number});
        this.setState({["confirmAccountNo"]: data.account_number});
      }
      this.validateForm();

    }

     async handleChange(event) {
      const { id, value } = event.target;
      console.log(id, value);
      this.setState({ [id]: value },  () => {
        this.validateForm();
      });

      if(id === "ifsccode"){
        if( value.length === 11){
        let bankDetails =  await getIfscCode(value);
        let bankName = bankDetails.BANK.toLowerCase() + ", " + bankDetails.BRANCH.toLowerCase();
        this.setState({["bankName"]: bankName});
        }else{
          this.setState({["bankName"]: ""});
        }
      }
     
    };
  
    handleSubmit = async (event) => {
      spinnerService.show("mainSpinner", "Please wait...");

      event.preventDefault();
     
      let reqParam = {
        fullName: this.state.fullName,
        ifsc: this.state.ifsccode,
        account_number: this.state.accountNo,
        account_type: this.state.accountType,
      };

      try{
        let pennyDropData = await pennyDrop(this.state.accountNo, this.state.ifsccode);
        console.log(pennyDropData);
        if(pennyDropData && pennyDropData.errors){
          spinnerService.hide("mainSpinner");
          
          this.setState({pennyMessage : "Penny drop failed for some reason, Please check your account details."})

          this.setState({pennyDropFails : true})
        }else{

          if(pennyDropData?.data?.penny_drop){
            if(pennyDropData?.data?.penny_drop.penny_drop_status==false){
              this.setState({pennyMessage : "Penny drop failed for some reason, Please check your account details."})

              this.setState({pennyDropFails : true});
              spinnerService.hide("mainSpinner");

              return;
            }
            if(pennyDropData?.data?.penny_drop.name_match_status==false){
              this.setState({pennyMessage : "Name match failed, Please check your account details."})

              this.setState({pennyDropFails : true});
              spinnerService.hide("mainSpinner");

              return;
            }
          }

          await updateSignApplication(reqParam);
          spinnerService.hide("mainSpinner");

            this.renderRedirect();
          
        }
        
      }catch(e){
        spinnerService.hide("mainSpinner");

        this.setState({pennyDropFails : true})
      }
     
     
    };

    validateForm = () => {
      if (
        this.state.fullName &&
        this.state.ifsccode && this.state.ifsccode.length ==11 &&
        this.state.accountType &&
        this.state.accountNo == this.state.confirmAccountNo
      ) {
        this.setState({ ["isValidated"]: true });
        console.log(this.state.isValidated);
        return true;
      } else {
        this.setState({ ["isValidated"]: false });
        console.log(this.state.isValidated);
        return false;
      }
    };

    render() {
        return (      <div className="col-md-6 col-lg-5 col-xl-4">

          <div  className="container my-auto py-4 bg-white shadow-lg rounded">
          <div className="row mt-2">
            <div className="col-11 col-lg-11 mx-auto">
              <h1>Bank Details</h1>

              <div className="contentArea">
                 
                </div>
              
              <form id="registerForm" className="form-border" method="post">

                <div className="form-group icon-group">
                  <input type="text" value={this.state.fullName} className="form-control floating-label-field floating-label-field--s1" id="fullName" required placeholder="Account Holder Name" onChange={this.handleChange}
                  onKeyUp={this.handleChange} />
                  <label class="floating-label">Account Holder Name</label>
                  <span className="icon-inside text-primary"><i className="fas fa-user"></i></span> </div>
                <div className="form-group icon-group">
                  <input maxLength="11" value={this.state.ifsccode} type="text" className="form-control floating-label-field floating-label-field--s1" id="ifsccode" required placeholder="IFSC Code" onChange={this.handleChange}
                  onKeyUp={this.handleChange} />
                  <label class="floating-label">IFSC Code</label>
                  <span className="icon-inside text-primary"><i className="fas fa-university"></i></span> </div>
             

                <div className="form-group icon-group bank-name-block">
                  <input value={this.state.bankName} type="text" className="form-control floating-label-field floating-label-field--s1 bank-name" id="bankName" required  readOnly = {true}/>
                </div>

                  <div className="form-group icon-group">
                  <select
                    id="accountType"
                    className="form-control floating-label-field floating-label-field--s1"
                    noValidate
                    onChange={this.handleChange}
                    value={this.state.accountType}
                  onKeyUp={this.handleChange}
                  >
                    <option value="">Select an option</option>
                    <option value="savings">Savings Account</option>
                    <option value="current">Current Account</option>
                  </select>

                  <label class="floating-label">Account Type</label>
                  {/* <input type="email" className="form-control floating-label-field floating-label-field--s1" id="emailAddress" required placeholder="Bank Account Type" /> */}
                  <span className="icon-inside text-primary"><i className="fas fa-piggy-bank"></i></span> </div>
                  <div className="form-group icon-group">
                  <input id="accountNo" value={this.state.accountNo}   onChange={this.handleChange}
                  onKeyUp={this.handleChange} className="form-control floating-label-field floating-label-field--s1" required placeholder="Bank Account Number" />
                  <label class="floating-label">Bank Account Number</label>
                  <span className="icon-inside text-primary"><i className="fas fa-university"></i></span> </div>
                  <div className="form-group icon-group">
                  <input id="confirmAccountNo" value={this.state.confirmAccountNo}  onChange={this.handleChange}
                  onKeyUp={this.handleChange} className="form-control floating-label-field floating-label-field--s1" required placeholder="Re-type Bank Account Number" />
                  <label class="floating-label">Re-type Bank Account Number</label>
                  <span className="icon-inside text-primary"><i className="fas fa-university"></i></span> </div>
                
                { this.state.pennyDropFails && (
                <p className="alert alert-danger alert-dismissible fade show text-left text-1">
                  {this.state.pennyMessage}
                </p>
                )}
                
                <button                 disabled={!this.state.isValidated}
 onClick={this.handleSubmit} className="btn btn-primary btn-block text-uppercase mt-4" type="button" >NEXT</button>
              </form>

            </div>
          </div>
        </div><Footer />
        </div>

            )
    }
}
export default withRouter(LinkBankAccount);