import React from "react";
import moment from "moment";
import { validateInput, emailValidator, mobileValidator, idValidator } from "./Validations";
import { relationshipData, idData } from "./datas";

const NomineeForm1 = ({ state, handleState, handleUserInput, maxPercentage }) => {
    const disabled = typeof state?.nominee_number !== "undefined";
    return (
        <>
            <form>
                <div className="form-group basic">
                    <label className="label">
                        Nominee Name <span style={{ color: "red" }}>*</span>
                    </label>
                    <div className="input-group">
                        <input
                            type="text"
                            name="name"
                            className="form-control"
                            // placeholder="Nominee Name"
                            onChange={(e) => handleUserInput(e)}
                            onBlur={(e) => handleUserInput(e)}
                            value={state?.name || ""}
                            disabled={disabled}
                        />
                    </div>
                    {/* <span className="error">Please enter nominee name</span> */}
                    <p className="error" style={{ color: "red" }}>
                        {validateInput("Nominee name", state?.name)}
                    </p>
                </div>
                <div className="form-group basic">
                    <label className="label">
                        Share Percentage <span style={{ color: "red" }}>*</span>
                    </label>
                    <div className="input-group">
                        <input
                            type="range"
                            name="percentage_of_share"
                            onChange={(e) => handleUserInput(e)}
                            onBlur={(e) => handleUserInput(e)}
                            value={state?.percentage_of_share || "0"}
                            min="0"
                            max={maxPercentage}
                            style={{ width: "100%" }}
                            disabled={disabled}
                        />
                    </div>
                    {/* <span className="error">Please choose share percentage 25%, 50%, 75% or 100%</span> */}
                    <span className="label" style={{ fontSize: 15 }}>
                        {state?.percentage_of_share || "0"}%
                    </span>
                    <p className="error" style={{ color: "red" }}>
                        {validateInput("Share percentage", state?.percentage_of_share)}
                    </p>
                </div>
                <div className="form-group basic">
                    <label className="label">
                        Relationship <span style={{ color: "red" }}>*</span>
                    </label>
                    <div className="input-group">
                        <select
                            name="relationship"
                            className="form-control form-select"
                            onChange={(e) => handleState("relationship", e.target.value ?? null)}
                            onBlur={(e) => handleState("relationship", e.target.value ?? null)}
                            style={{ width: "200px" }}
                            value={state?.relationship || ""}
                            disabled={disabled}
                        >
                            <option value=""></option>
                            {relationshipData.map((e, i) => {
                                return (
                                    <option key={i} value={e.value}>
                                        {e.label}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                    {/* <span className="error">
                        Please select relationship. for example - father, mother, brother, sister
                    </span> */}
                    <p className="error" style={{ color: "red" }}>
                        {validateInput("Relationship", state?.relationship)}
                    </p>
                </div>
                <div className="form-group basic">
                    <label className="label">
                        Nominee Address <span style={{ color: "red" }}>*</span>
                    </label>
                    <div className="input-group">
                        <input
                            type="text"
                            name="address"
                            className="form-control"
                            // placeholder="residential address of the nominee"
                            onChange={(e) => handleUserInput(e)}
                            onBlur={(e) => handleUserInput(e)}
                            value={state?.address || ""}
                            disabled={disabled}
                        />
                    </div>
                    {/* <span className="error">Please enter residence address of nominee</span> */}
                    <p className="error" style={{ color: "red" }}>
                        {validateInput("Nominee address", state?.address)}
                    </p>
                </div>
                <div className="form-group basic">
                    <label className="label">
                        Nominee Mobile <span style={{ color: "red" }}>*</span>
                    </label>
                    <div className="input-group">
                        <label style={{ marginTop: "6.5px", paddingRight: "10px" }}>+91</label>
                        <input
                            type="text"
                            name="mobile"
                            className="form-control"
                            // placeholder="mobile number of the nominee"
                            onChange={(e) => handleState("mobile", e.target.value)}
                            onBlur={(e) => handleState("mobile", e.target.value)}
                            value={state?.mobile || ""}
                            minLength={10}
                            maxLength={10}
                            disabled={disabled}
                        />
                    </div>
                    {/* <span className="error">Please enter mobile number of nominee</span> */}
                    <p className="error" style={{ color: "red" }}>
                        {mobileValidator(state?.mobile)}
                    </p>
                </div>
                <div className="form-group basic">
                    <label className="label">
                        Nominee Email <span style={{ color: "red" }}>*</span>
                    </label>
                    <div className="input-group">
                        <input
                            type="email"
                            name="email"
                            className="form-control"
                            // placeholder="email of the nominee"
                            onChange={(e) => handleUserInput(e)}
                            onBlur={(e) => handleUserInput(e)}
                            value={state?.email || ""}
                            disabled={disabled}
                        />
                    </div>
                    {/* <span className="error">Please enter email address of nominee</span> */}
                    <p className="error" style={{ color: "red" }}>
                        {emailValidator(state?.email)}
                    </p>
                </div>
                <div className="form-group basic">
                    <label className="label">
                        Nominee ID Type <span style={{ color: "red" }}>*</span>
                    </label>
                    <div className="input-group">
                        <select
                            name="id_type"
                            className="form-control form-select"
                            onChange={(e) => handleState("id_type", e.target.value ?? null)}
                            style={{ width: "200px" }}
                            value={state?.id_type || ""}
                            disabled={disabled}
                        >
                            <option value=""></option>
                            {idData.map((e, i) => {
                                return (
                                    <option key={i} value={e.value}>
                                        {e.label}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                    {/* <span className="error">Please select id type of nominee</span> */}
                    <p className="error" style={{ color: "red" }}>
                        {validateInput("Nominee ID type", state?.id_type)}
                    </p>
                </div>
                <div className="form-group basic">
                    <label className="label">
                        Nominee ID Number <span style={{ color: "red" }}>*</span>
                    </label>
                    <div className="input-group">
                        <input
                            type="text"
                            name="id_number"
                            className="form-control"
                            // placeholder="id number of the nominee"
                            onChange={(e) => handleUserInput(e)}
                            onBlur={(e) => handleUserInput(e)}
                            value={state?.id_number || ""}
                            disabled={disabled}
                        />
                    </div>
                    {/* <span className="error">Please enter id number of nominee</span> */}
                    <p className="error" style={{ color: "red" }}>
                        {/* {validateInput("Nominee id no.", state?.id_number)} */}
                        {idValidator(state?.id_type, state?.id_number)}
                    </p>
                </div>
                <div className="form-group basic">
                    <label className="label">Is nominee minor?</label>
                    <div className="input-group">
                        <input
                            type="checkbox"
                            name="minor"
                            onChange={() => handleState("minor", !state?.minor ?? true)}
                            value={state?.minor || false}
                            checked={state?.minor || false}
                            style={{ height: "20px", width: "20px" }}
                            disabled={disabled}
                        />
                    </div>
                </div>
                {state?.minor && (
                    <>
                        <div className="form-group basic">
                            <label className="label">
                                Date of Birth <span style={{ color: "red" }}>*</span>
                            </label>
                            <div className="input-group">
                                {!disabled ? (
                                    <input
                                        type="date"
                                        name="dob_if_minor"
                                        className="form-control"
                                        // placeholder="id number of the nominee"
                                        onChange={(e) => handleState("dob_if_minor", e.target.value)}
                                        value={state?.dob_if_minor || moment().format("YYYY-MM-DD")}
                                        min={moment().subtract(18, "years").format("YYYY-MM-DD")}
                                        max={moment().format("YYYY-MM-DD")}
                                        onKeyDown={(e) => e.preventDefault()}
                                    />
                                ) : (
                                    <input
                                        type="text"
                                        name="dob_if_minor"
                                        className="form-control"
                                        value={state?.dob_if_minor || ""}
                                        disabled={true}
                                    />
                                )}
                            </div>
                            {/* <span className="error">Please enter date of birth of minor nominee</span> */}
                            <p className="error" style={{ color: "red" }}>
                                {validateInput("Date of birth", state?.dob_if_minor)}
                            </p>
                        </div>

                        <div className="form-group basic">
                            <label className="label">
                                Name of Guardian <span style={{ color: "red" }}>*</span>
                            </label>
                            <div className="input-group">
                                <input
                                    type="text"
                                    name="guardian_name"
                                    className="form-control"
                                    // placeholder="name of guardian for the minor nominee"
                                    onChange={(e) => handleUserInput(e)}
                                    onBlur={(e) => handleUserInput(e)}
                                    value={state?.guardian_name || ""}
                                    disabled={disabled}
                                />
                            </div>
                            {/* <span className="error">Please enter guardian name for the minor nominee</span> */}
                            <p className="error" style={{ color: "red" }}>
                                {validateInput("Name of guardian", state?.guardian_name)}
                            </p>
                        </div>

                        <div className="form-group basic">
                            <label className="label">
                                Address of Guardian <span style={{ color: "red" }}>*</span>
                            </label>
                            <div className="input-group">
                                <input
                                    type="text"
                                    name="guardian_address"
                                    className="form-control"
                                    // placeholder="address of guardian for the minor nominee"
                                    onChange={(e) => handleUserInput(e)}
                                    onBlur={(e) => handleUserInput(e)}
                                    value={state?.guardian_address || ""}
                                    disabled={disabled}
                                />
                            </div>
                            {/* <span className="error">Please enter guardian address for the minor nominee</span> */}
                            <p className="error" style={{ color: "red" }}>
                                {validateInput("Address of guardian", state?.guardian_address)}
                            </p>
                        </div>

                        <div className="form-group basic">
                            <label className="label">
                                Mobile of Guardian <span style={{ color: "red" }}>*</span>
                            </label>
                            <div className="input-group">
                                <label style={{ marginTop: "7px", paddingRight: "10px" }}>+91</label>
                                <input
                                    type="text"
                                    name="guardian_mobile"
                                    className="form-control"
                                    // placeholder="mobile of guardian for the minor nominee"
                                    onChange={(e) => handleState("guardian_mobile", e.target.value)}
                                    onBlur={(e) => handleState("guardian_mobile", e.target.value)}
                                    value={state?.guardian_mobile || ""}
                                    minLength={10}
                                    maxLength={10}
                                    disabled={disabled}
                                />
                            </div>
                            {/* <span className="error">Please enter guardian mobile for the minor nominee</span> */}
                            <p className="error" style={{ color: "red" }}>
                                {mobileValidator(state?.guardian_mobile)}
                            </p>
                        </div>

                        <div className="form-group basic">
                            <label className="label">
                                Email of Guardian <span style={{ color: "red" }}>*</span>
                            </label>
                            <div className="input-group">
                                <input
                                    type="email"
                                    name="guardian_email"
                                    className="form-control"
                                    // placeholder="email of guardian for the minor nominee"
                                    onChange={(e) => handleUserInput(e)}
                                    onBlur={(e) => handleUserInput(e)}
                                    value={state?.guardian_email || ""}
                                    disabled={disabled}
                                />
                            </div>
                            {/* <span className="error">Please enter guardian email for the minor nominee</span> */}
                            <p className="error" style={{ color: "red" }}>
                                {emailValidator(state?.guardian_email)}
                            </p>
                        </div>

                        <div className="form-group basic">
                            <label className="label">
                                Relationship with Guardian <span style={{ color: "red" }}>*</span>
                            </label>
                            <div className="input-group">
                                <select
                                    name="guardian_relationship"
                                    className="form-control form-select"
                                    onChange={(e) => handleState("guardian_relationship", e.target.value ?? null)}
                                    style={{ width: "200px" }}
                                    value={state?.guardian_relationship || ""}
                                    disabled={disabled}
                                >
                                    <option value=""></option>
                                    {relationshipData.map((e, i) => {
                                        return (
                                            <option key={i} value={e.value}>
                                                {e.label}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                            {/* <span className="error">Please select guardian relation with the minor nominee</span> */}
                            <p className="error" style={{ color: "red" }}>
                                {validateInput("Relation of guardian", state?.guardian_relationship)}
                            </p>
                        </div>

                        <div className="form-group basic">
                            <label className="label">
                                Guardian Id Type <span style={{ color: "red" }}>*</span>
                            </label>
                            <div className="input-group">
                                <select
                                    name="guardian_id_type"
                                    className="form-control form-select"
                                    onChange={(e) => handleState("guardian_id_type", e.target.value ?? null)}
                                    style={{ width: "200px" }}
                                    value={state?.guardian_id_type || ""}
                                    disabled={disabled}
                                >
                                    <option value=""></option>
                                    {idData.map((e, i) => {
                                        return (
                                            <option key={i} value={e.value}>
                                                {e.label}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                            {/* <span className="error">Please select guardian id type</span> */}
                            <p className="error" style={{ color: "red" }}>
                                {validateInput("ID type of guardian", state?.guardian_id_type)}
                            </p>
                        </div>

                        <div className="form-group basic">
                            <label className="label">
                                Guardian Id Number <span style={{ color: "red" }}>*</span>
                            </label>
                            <div className="input-group">
                                <input
                                    type="text"
                                    name="guardian_id_number"
                                    className="form-control"
                                    // placeholder="id number of guardian"
                                    onChange={(e) => handleUserInput(e)}
                                    onBlur={(e) => handleUserInput(e)}
                                    value={state?.guardian_id_number || ""}
                                    disabled={disabled}
                                />
                            </div>
                            {/* <span className="error">Please enter guardian id number</span> */}
                            <p className="error" style={{ color: "red" }}>
                                {idValidator(state?.guardian_id_type, state?.guardian_id_number)}
                            </p>
                        </div>
                    </>
                )}
            </form>
        </>
    );
};

export default NomineeForm1;
