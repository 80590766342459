import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import {workFlowStepUpdate} from "../../util/login";
import Footer from "../../Components/footer";

import {renderRedirect} from "../../util";
import {createRazorpayOrder} from "./api";
const configApp = require("../../config");

class PayFees extends Component {

  constructor(props){
    super(props);
    this.state = {
      errorMessage: ""
    }
  }

  renderRedirect = () => {
    renderRedirect(this.props, "/awesome");
  }

  initateRazorPay = async () => {
    let orderId = "";
    let order = await createRazorpayOrder("30000", "INR", "reciept_"+new Date().valueOf());
    if(order && order.data && order.data.create_razorpay_order){
      orderId = order.data.create_razorpay_order.razorpay_order_id;
    }
    const options = {
      key: configApp.REACT_APP_RZR_PAY_KEY,
      prefill: {
        email: localStorage.getItem("email"),
        contact:  localStorage.getItem("mobile")
      },
      currency: "INR",
      name: "TRDR",
      description: "Account opening fee",
      order_id: orderId,
      handler: (response) => {
        try {
          const paymentId = response.razorpay_payment_id;
          //workFlowStepUpdate(localStorage.getItem('identifier')).then(res=>{
            
         // });

          this.renderRedirect();
         } catch (err) {
           console.log(err);
           this.setState({ errorMessage: "Some error occurred, Please contact support team" });

         }
      },
    };
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

    render() {
        return (      <div className="col-md-6 col-lg-5 col-xl-4">

          <div className="container my-auto py-4 bg-white shadow-lg rounded">
          <div className="row mt-2">


            <div className="col-11 col-lg-11 mx-auto">
              <h1 className="strike-through">Pay Fees</h1>
              <br></br>
              <p className="text-left"><img className="img-fluid" width="50%" src="../images/trdr-horizontal.png" alt="verification"/></p>
              <br/>
              <p className="text-muted text-2 text-left">We charge a TRDR 
account opening fee of <span className="strike-through">Rs 300.</span></p>

              <p>
              {this.state.errorMessage ? (
                <span style={{ display: "block" }} className="alert alert-warning alert-dismissible fade show">
                  {this.state.errorMessage}
                </span>
              ) : (
                ""
              )}
              </p>

              <p>
              
                <span style={{ display: "block" }} className="alert alert-success alert-dismissible fade show">
                  We are waiving off your TRDR account opening fees
                </span>
              
              </p>


              <form id="otp-screen" className="form-border" method="post">
              <br/>
                <button onClick={this.renderRedirect} className="btn btn-primary btn-block my-3" type="button">NEXT</button>
              </form>
            </div>

          </div>
        </div><Footer />
        </div>
            )
    }
}
export default withRouter(PayFees);