import jwt_decode from "jwt-decode";
import { workFlowSteps } from "../util";
import { mutation } from "../api";
import { spinnerService } from "../Components/spinner.service";

export async function requireLogin(to, from, next) {
  let url = "";
  console.log("currentWorkflowId, currentWorkflowName");
  spinnerService.show("mainSpinner", "Please wait...");

  const workFlowObj = await getCurrentWorkflowId().catch((err) => {});

  console.log("currentWorkflowId, currentWorkflowName", workFlowObj);

  if (workFlowObj?.currentWorkflowId && workFlowObj?.currentWorkflowName) {
    url = getCurrentRedirectUrl(workFlowObj?.currentWorkflowName);
  }

  if (to.meta.auth) {
    const { localStorage } = window;
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const userToken = jwt_decode(token);
        const currentTimeStamp = Math.round(new Date().getTime() / 1000);
        const exp = userToken.exp - currentTimeStamp;
        if (!token || exp <= 0) {
          spinnerService.hide("mainSpinner");
          next.redirect("/");
        } else {
          spinnerService.hide("mainSpinner");
          if (url) {
            next.redirect("/" + url);
          } else {
            next();
          }
        }
      } catch (e) {
        spinnerService.hide("mainSpinner");
        next.redirect("/");
        console.log(e);
      }
    } else {
      spinnerService.hide("mainSpinner");
      next.redirect("/");
    }
  } else {
    spinnerService.hide("mainSpinner");
    next();
  }
}
export function isTokenExpired() {
  return false;
}

export async function getCurrentWorkflowId() {
  let workflow = await getCurrentWorkflow();
  if (workflow?.data?.get_current_workflow?.current_workflow_step_id) {
    const { workflow_steps, current_workflow_step_id } =
      workflow.data.get_current_workflow;
    const currentWorkflowId = current_workflow_step_id;
    const currentWorkflowObj = (workflow_steps || []).find(
      (obj) => obj._id === currentWorkflowId
    );
    localStorage.setItem("identifier", currentWorkflowId);
    localStorage.setItem("identifierName", currentWorkflowObj?.name);
    return { currentWorkflowId, currentWorkflowName: currentWorkflowObj?.name };
  }
}

export function getCurrentRedirectUrl(currentWorkflowName) {
  let url = "";
  let workFlowRedirect = workFlowSteps();

  const workFlowObj = workFlowRedirect.find(
    (obj) => obj.name === currentWorkflowName
  );
  // workFlowRedirect.forEach(res=>{
  //   if(res._id == currentWorkflowId){
  //     url =  res.redirect;
  //   }
  // });
  url = workFlowObj?.redirect || null;
  return url;
}

export async function getCurrentWorkflow() {
  return mutation(
    "get_current_workflow",
    {},
    `status, _id, signup_application_id, workflow_status, current_workflow_step_id, workflow_steps{_id,name}`
  );
}

export async function workFlowStepUpdate(workflow_step_id) {
  if (workflow_step_id) {
    return mutation(
      "workflow_step_submit",
      { workflow_step_id },
      `status
  message
  current_workflow_step_id`
    );
  }
}
