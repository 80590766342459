import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { renderRedirect } from "../util";
import {fetchSignApplication, updateSignApplication} from "./BasicProfile/api"
import Footer from "../Components/footer";

class AddressDetails extends Component {
  renderRedirect = () => {
    renderRedirect(this.props, "/additional");
  };
  constructor(props) {
    super(props);
    this.state = {
      residentialStatus: null,
      permanentAddress: null,
      communicationAddress: null,
      isValidated: false,
      errors: {
        residentialStatus: "",
        permanentAddress: "",
        communicationAddress: "",
      },
    };
  }
  validateForm = () => {
    //console.log(this.state.fullName +"-dob-"+this.state.dateOfBirth+"--pan-"+this.state.panNumber+"--mas-"+this.state.maritalStatus+"-gen--"+this.state.gender);
    if (
      this.state.residentialStatus &&
      this.state.permanentAddress &&
      this.state.communicationAddress
    ) {
      this.setState({ ["isValidated"]: true });
      console.log(this.state.isValidated);
      return true;
    } else {
      this.setState({ ["isValidated"]: false });
      console.log(this.state.isValidated);
      return false;
    }
  };

  async componentDidMount() {
    let profileData = await fetchSignApplication();
    if(profileData && profileData.data && profileData.data.fetch_signup_application){
      let data = profileData.data.fetch_signup_application;
      this.setState({["residentialStatus"]: data.residentialStatus});
      this.setState({["permanentAddress"]: decodeURIComponent( data.permanentAddress )});
      this.setState({["communicationAddress"]: decodeURIComponent( data.communicationAddress)});
    }
    window.setTimeout(function () {
      this.validateForm();
    }.bind(this), 600);
  }

  handleChange = (event) => {
    let errors = this.state.errors;
    event.preventDefault();
    const { id, value } = event.target;
    switch (id) {
      case "residentialStatus":
        if (value.length < 1) {
          //errors.residentialStatus = "Residential Address cannot be empty.";
          this.setState({ ["residentialStatus"]: null });
        } else {
          errors.residentialStatus = "";
          this.setState({ ["residentialStatus"]: value });
        }
        break;
      case "permanentAddress":
        if (value.length < 1) {
         // errors.permanentAddress = "Permanant Address cannot be empty.";
          this.setState({ ["permanentAddress"]: null });
        } else {
          errors.permanentAddress = "";
          this.setState({ ["permanentAddress"]: value });
        }
        break;
      case "communicationAddress":
        if (value.length < 1) {
         // errors.communicationAddress =
           // "Communication Address cannot be empty.";
          this.setState({ ["communicationAddress"]: null });
        } else {
          errors.communicationAddress = "";
          this.setState({ ["communicationAddress"]: value });
        }
        break;
      default:
        break;
    }
    this.setState({ errors });
    window.setTimeout(function () {
      this.validateForm();
    }.bind(this), 600);
   
  };

  handleSubmit = async(event) => {
    event.preventDefault();
    if (this.validateForm()) {
      console.info("Valid Form");

      let reqParam = {
        residentialStatus: this.state.residentialStatus,
        permanentAddress: this.state.permanentAddress,
        communicationAddress: this.state.communicationAddress
      };
      let updateProfile = await updateSignApplication(reqParam);

      this.renderRedirect();
    } else {
      console.error("Invalid Form");
    }
  };
  render() {
    const { errors } = this.state;
    return (      <div className="col-md-6 col-lg-5 col-xl-4">

      <div className="container my-auto py-4 bg-white shadow-lg rounded">
        <div className="row mt-2">
          <div className="col-11 col-lg-11 mx-auto">
            <h1>Address Details</h1>

            <form id="registerForm" className="form-border" method="post">
              <div className="form-group icon-group floating-label-wrap">
              

<select
                  id="residentialStatus"
                  className="form-control  floating-label-field floating-label-field--s1"
                  onChange={this.handleChange}
                  onBlur={this.handleChange}
                  noValidate
                  value={this.state.residentialStatus}
                > <option value="">Select an option</option>

                  <option value="Resident Individual">Resident Individual</option>
                  <option value="Non Resident Indian">Non Resident Indian</option>
                  <option value="Foreign National">Foreign National</option>
                  <option value="Person of Indian Origin">Person of Indian Origin</option>
                </select>

                 <label class="floating-label">Residential Status</label>
                <span className="icon-inside text-primary">
                  <i className="fas fa-address-card"></i>
                </span>   {errors.residentialStatus.length > 0 && (
                  <span className="error">{errors.residentialStatus}</span>
                )}

              </div>
              <div className="form-group icon-group floating-label-wrap">
                <textarea 
                  type="text"
                  rows="4"
                  className="form-control floating-label-field floating-label-field--s1"
                  id="permanentAddress"
                  required
                  placeholder="Permanent Address"
                  onChange={this.handleChange}
                  onBlur={this.handleChange}
                  noValidate
                  value={this.state.permanentAddress}
                />
                 <label class="floating-label">Permanent Address</label>
                <span className="icon-inside text-primary">
                  <i className="fas fa-address-card"></i>
                </span>
                {errors.permanentAddress.length > 0 && (
                  <span className="error">{errors.permanentAddress}</span>
                )}
              </div>
              <div className="form-group icon-group floating-label-wrap">
                <textarea 
                  rows="4"
                  type="text"
                  className="form-control floating-label-field floating-label-field--s1"
                  id="communicationAddress"
                  required
                  placeholder="Communication Address"
                  onChange={this.handleChange}
                  onBlur={this.handleChange}
                  noValidate
                  value={this.state.communicationAddress}
                />
                 <label class="floating-label">Communication Address</label>
                <span className="icon-inside text-primary">
                  <i className="fas fa-address-card"></i>
                </span>
                {errors.communicationAddress.length > 0 && (
                  <span className="error">{errors.communicationAddress}</span>
                )}
              </div>

              <button
                className="btn btn-primary btn-block text-uppercase mt-4"
                type="button"
                onClick={this.handleSubmit}
                disabled={!this.state.isValidated}

              >
                NEXT
              </button>
            </form>
          </div>
        </div>
      </div><Footer /></div>
    );
  }
}
export default withRouter(AddressDetails);