module.exports = {
  REACT_APP_API_URL: process.env.REACT_APP_API_URL,
  REACT_APP_BASE_URL: process.env.REACT_APP_BASE_URL,
  REACT_APP_HEADER_ORG: process.env.REACT_APP_HEADER_ORG,
  REACT_APP_BASE_URL_SIGN_IN: process.env.REACT_APP_BASE_URL_SIGN_IN,
  REACT_APP_RZR_PAY_KEY: process.env.REACT_APP_RZR_PAY_KEY,
  REACT_APP_RZR_PAY_PSS: process.env.REACT_APP_RZR_PAY_PSS,
  REACT_APP_DIGIO_ENV: process.env.REACT_APP_DIGIO_ENV,
  REACT_APP_PRIVACY: process.env.REACT_APP_PRIVACY,
};
