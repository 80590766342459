import React, { useRef, useState } from "react";
import {
  FileUploadContainer,
  FormField,
  DragDropText,
  UploadFileBtn,
  FilePreviewContainer,
  ImagePreview,
  PreviewContainer,
  PreviewList,
  FileMetaData,
  RemoveFileIcon,
  InputLabel,
} from "./file-upload.styles";

const KILO_BYTES_PER_BYTE = 1024;
const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 5000000;

const convertNestedObjectToArray = (nestedObj) =>
  Object.keys(nestedObj).map((key) => nestedObj[key]);

const convertBytesToKB = (bytes) => {
  const kb = bytes / KILO_BYTES_PER_BYTE;
  const amount = 0;
  if (kb >= 1000) {
    if(kb / KILO_BYTES_PER_BYTE > 5){
      return "~ 5MB";
    }
    return (kb / KILO_BYTES_PER_BYTE).toFixed(1) + " MB";
  } else {
    return Math.round(kb) + " KB";
  }
};

const FileUpload = ({
  label,
  updateFilesCb,
  maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES + (DEFAULT_MAX_FILE_SIZE_IN_BYTES * .30),
  ...otherProps
}) => {
  const fileInputField = useRef(null);
  const [files, setFiles] = useState({});
  let [error_msg] = useState("");

  const handleUploadBtnClick = () => {
    fileInputField.current.click();
  };

  const addNewFiles = (newFiles) => {
    for (let file of newFiles) {
      if (file.size <= maxFileSizeInBytes) {
        if (!otherProps.multiple) {
          return { file };
        }
        files[file.name] = file;
      } else {
        error_msg = "Exceeds maximum file size (5 MB)";
      }
    }
    return { ...files };
  };

  const callUpdateFilesCb = (files) => {
    if (error_msg) {
      updateFilesCb(error_msg);
    } else {
      const filesAsArray = convertNestedObjectToArray(files);
      updateFilesCb(filesAsArray);
    }
  };

  const handleNewFileUpload = (e) => {
    const { files: newFiles } = e.target;
    if (newFiles.length) {
      error_msg = "";
      let updatedFiles = addNewFiles(newFiles);
      setFiles(updatedFiles);
      callUpdateFilesCb(updatedFiles);
    }
  };

  const removeFile = (fileName) => {
    delete files[fileName];
    setFiles({ ...files });
    callUpdateFilesCb({ ...files });
  };

  return (
    <>
      <FileUploadContainer>
        <InputLabel>{label}</InputLabel>
        <DragDropText>
          Drag and drop <br />
          or <br />
          Click here to upload file
        </DragDropText>

        {Object.keys(files).map((fileName, index) => {
          let file = files[fileName];
          return (
            <div className="fileItem" key={index}>
              <span>{file.name}</span>
              <span className="fileSize">{convertBytesToKB(file.size)}</span>
            </div>
          );
        })}
        <FormField
          type="file"
          ref={fileInputField}
          onChange={handleNewFileUpload}
          title=""
          value=""
          {...otherProps}
        />
      </FileUploadContainer>
    </>
  );
};

export default FileUpload;