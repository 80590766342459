import logo from "./logo.svg";
import {
  BrowserRouter,
  Redirect
} from "react-router-dom";

import React, { Component } from 'react';
import { createBrowserHistory } from "history";
import App from './App';
import { Route } from 'react-router-dom';

class Wrapper extends Component{

  constructor(props) {
    super(props);
  }
 

  render(){
    return (
      
            <BrowserRouter>
              <Route component={App} />

              <Route exact path="/">
                      </Route>    
            </BrowserRouter>
          
    );
  }
  


}
export default Wrapper;
