import React from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";

import Footer from "../../Components/footer";
import CollapseCard from "./CollapseCard";
import Form from "./Form";
import "./CollapseCard.css";

import { renderRedirect } from "../../util";
import { updateSignApplication, fetchSignApplication } from "../BasicProfile/api";
import { validateForm } from "./Validations";

class AddNominee extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isValid: false,
            pincode: null,
            activeCard: "1",
            nominee1: null,
            nominee2: null,
            nominee3: null,
        };
    }

    async componentDidMount() {
        let profileData = await fetchSignApplication();
        if (profileData && profileData.data && profileData.data.fetch_signup_application) {
            let data = profileData?.data?.fetch_signup_application;
            this.setState({
                pincode: data?.permanentAddress ? data.permanentAddress.substring(data.permanentAddress.length - 6) : null,
            });
        }
    }

    handleAddNominee = (data) => {
        if (data && data.length <= 3) {
            if (data.length === 3) {
                return false;
            }
            let sum = data.reduce((accumulator, curValue) => {
                return accumulator + parseInt(curValue.percentage_of_share);
            }, 0);
            return 100 - sum !== 0 ? true : false;
        } else {
            return true;
        }
    };

    saveNominee() {
        let data = [];
        let totalPercentage = 100;

        for (let e = 1; e < 4; e++) {
            let obj = this.state[`nominee${e}`];
            if (obj) {
                if (obj?.name) {
                    let validation = validateForm(obj, `nominee${e}`);
                    if (!validation.status) {
                        window.alert(validation.err);
                        data = [];
                        break;
                    }

                    totalPercentage -= parseInt(obj?.percentage_of_share);

                    let payload = {};
                    payload.nominee_number = obj?.nominee_number ?? `${e}`;
                    payload.name = obj.name;
                    payload.percentage_of_share = obj?.percentage_of_share;
                    payload.relationship = obj.relationship;
                    payload.address = obj.address;
                    payload.mobile = obj.mobile;
                    payload.email = obj.email;
                    payload.id_type = obj.id_type;
                    payload.id_number = obj.id_number;
                    if (obj.minor) {
                        payload.dob_if_minor = obj?.dob_if_minor
                            ? obj?.dob_if_minor.split("-").reverse().join("/")
                            : moment().format("DD/MM/YYYY");
                        payload.minor_guardian_info = {
                            guardian_name: obj.guardian_name,
                            guardian_address: obj.guardian_address,
                            guardian_mobile: obj.guardian_mobile,
                            guardian_email: obj.guardian_email,
                            guardian_relationship: obj.guardian_relationship,
                            guardian_id_type: obj.guardian_id_type,
                            guardian_id_number: obj.guardian_id_number,
                        };
                    }
                    data.push(payload);
                }
            }
        }

        if (data.length) {
            return this.updateNomineeDetails(data);
        }

        return;
    }

    async updateNomineeDetails(data) {
        if (data.length) {
            let reqParam = {
                nominee_form_pincode: this.state.pincode,
                nominees: data,
            };
            let updateProfile = await updateSignApplication(reqParam);
            // let update = await add_nominee_details(this.state.profile.pincode, data);
            if (!updateProfile?.data) {
                return window.alert(updateProfile?.errors[0]?.message || "Something Went Wrong");
            } else {
                return renderRedirect(this.props, "/bank-cheque");
            }
        }
        return;
    }

    handleUserInput = (event) => {
        const name = event.target.name;
        switch (name) {
            default:
                let data = { [event.target.name]: event.target.value };
                this.setState({
                    [`nominee${this.state.activeCard}`]: { ...this.state[`nominee${this.state.activeCard}`], ...data },
                });
                break;
        }
    };

    handleSetState = (key, value) => {
        let data = { [key]: value };
        if (key === "minor") {
            if (!value) {
                let fields = [
                    "dob_if_minor",
                    "guardian_name",
                    "guardian_address",
                    "guardian_mobile",
                    "guardian_email",
                    "guardian_relationship",
                    "guardian_id_type",
                    "guardian_id_number",
                ];
                fields.map((e) => delete this.state[`nominee${this.state.activeCard}`][e]);
            }
            this.setState({
                [`nominee${this.state.activeCard}`]: { ...this.state[`nominee${this.state.activeCard}`], ...data },
            });
        } else {
            this.setState({
                [`nominee${this.state.activeCard}`]: { ...this.state[`nominee${this.state.activeCard}`], ...data },
            });
        }
    };

    handleActiveCard = (value) => {
        if (value === this.state.activeCard) {
            this.setState({ activeCard: value });
        } else {
            if (value === "1") {
                this.setState({ activeCard: value });
            } else if (value === "2") {
                if (this.state[`nominee${value - 1}`]) {
                    if (this.fetchPercentage("2") !== "0") {
                        this.setState({ activeCard: value });
                    }
                }
            } else if (value === "3") {
                if (this.state[`nominee${value - 1}`]) {
                    if (this.fetchPercentage("3") !== "0") {
                        this.setState({ activeCard: value });
                    }
                }
            }
        }
    };

    fetchPercentage = (e) => {
        let total = 100;
        const { nominee1, nominee2, nominee3 } = this.state;
        if (e === "1")
            if (nominee1) {
                if (nominee2?.percentage_of_share) {
                    total = total - parseFloat(nominee2?.percentage_of_share);
                }
                if (nominee3?.percentage_of_share) {
                    total = total - parseFloat(nominee3?.percentage_of_share);
                }
            }
        if (e === "2") {
            if (nominee1?.percentage_of_share) {
                total = total - parseFloat(nominee1?.percentage_of_share);
            }
            if (nominee3?.percentage_of_share) {
                total = total - parseFloat(nominee3?.percentage_of_share);
            }
        }
        if (e === "3") {
            if (nominee1?.percentage_of_share) {
                total = total - parseFloat(nominee1?.percentage_of_share);
            }
            if (nominee2?.percentage_of_share) {
                total = total - parseFloat(nominee2?.percentage_of_share);
            }
        }
        if (e === "0") {
            if (nominee1?.percentage_of_share) {
                total = total - parseFloat(nominee1?.percentage_of_share);
            }
            if (nominee2?.percentage_of_share) {
                total = total - parseFloat(nominee2?.percentage_of_share);
            }
            if (nominee3?.percentage_of_share) {
                total = total - parseFloat(nominee3?.percentage_of_share);
            }
        }
        return total.toString();
    };

    render() {
        return (
            <div className="col-md-6 col-lg-5 col-xl-4">
                <div className="container my-auto py-4 bg-white shadow-lg rounded">
                    <div className="row mt-2">
                        <div className="col-11 col-lg-11 mx-auto">
                            <h1>Nominee Details</h1>
                            <div className="nominee-form">
                                {["1", "2", "3"].map((e) => {
                                    return (
                                        <CollapseCard
                                            key={e}
                                            active={this.state.activeCard === e}
                                            onClick={() => this.handleActiveCard(e)}
                                            title={`Nominee ${e}`}
                                            content={
                                                <Form
                                                    state={this.state[`nominee${e}`]}
                                                    handleState={this.handleSetState}
                                                    handleUserInput={this.handleUserInput}
                                                    maxPercentage={this.fetchPercentage(e)}
                                                />
                                            }
                                        />
                                    );
                                })}
                            </div>
                            <button
                                className="btn btn-primary btn-block text-uppercase mt-4"
                                type="button"
                                onClick={() => this.saveNominee()}
                                disabled={!this.state.nominee1 && !this.state.nominee2 && !this.state.nominee3}
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}
export default withRouter(AddNominee);
