
export function setDigioOption(env, logoURL="", primaryColor="", secondaryColor="", call_fun){
  var options = {
      environment : env,
      callback : function (response){
        if(response.hasOwnProperty("error_code")){
          return console.log("error occurred in process");
        }else{
          call_fun();
          console.log("Signing completed successfully");
        }            
      },
      is_iframe: true,
      redirect_url : "",
      logo : logoURL,
      theme : {
        primaryColor : primaryColor,
        secondaryColor :secondaryColor
      }
    }      
    return options;
}