import React, { Component } from 'react';
import { withRouter } from "react-router-dom";

import {renderRedirect} from "../util"
import {generateDocument} from "./Pay/api"
import {setDigioOption} from "../util/digio"
// import { spinnerService } from "../Components/spinner.service";

import Footer from "../Components/footer";
const configApp = require('../config');

 
class ReviewDocument extends Component {


  renderRedirect = () => {
    renderRedirect(this.props, "/complete");
  }

  callDigio = async() =>{
    // spinnerService.show("mainSpinner", "Please wait...");

    let doc = await generateDocument();
    let docid;

    if(doc.errors){
      // spinnerService.hide("mainSpinner");
      return false;
    }

    if(doc && doc.data){
      docid = doc.data.generate_agreement_document?.id;
    }
    let documentData = doc.data.generate_agreement_document;
    let options = setDigioOption(configApp.REACT_APP_DIGIO_ENV,"https://uat.trdr.in/images/trdr-horizontal.png", "#000000", "#FFFFFF", this.renderRedirect);
    try{
      console.log({documentData});
      console.log({ options });
      let digio = new window.Digio(options);
      digio.init();
      // spinnerService.hide("mainSpinner");
      digio.submit(
          documentData.id,
          documentData.customer_identifier,
          documentData.access_token.id
        );
    }catch(e){
      // spinnerService.hide("mainSpinner");
      console.log("digio document submit", e);
    }      
  }

    render() {
        return (      <div className="col-md-6 col-lg-5 col-xl-4">

          <div  className="container my-auto py-4 bg-white shadow-lg rounded">
          <div className="row mt-2">
            <div className="col-11 col-lg-11 mx-auto">
              <h1>Review Document</h1>

              <div className="contentArea">

<p>We are preparing your account 
opening document for you to sign.</p>
<p  className="text-2">After review, eSign your form 
using your Aadhaar Number</p>

<p className="text-2">
  <ul>
    <li>Click Review to view your 
account opening form to 
Sign it.</li>
    <li>Enter your Aadhaar 
number</li>
<li>Enter OTP received on 
mobile number linked to 
with Aadhaar</li>
  </ul>
</p>


                </div>
              
              <form id="registerForm" className="form-border" method="post">

            
               
                <button className="btn btn-primary btn-block text-uppercase mt-4" type="button" onClick={this.callDigio}>REVIEW</button>
              </form>

            </div>
          </div>
        </div><Footer />
        </div>

            )
    }
}
export default withRouter(ReviewDocument);
