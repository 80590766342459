import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { renderRedirect } from "../util";
import FileUpload from "../Components/file-upload.component";
import { mutation } from "../api";
import { updateSignApplication } from "./BasicProfile/api";
import { spinnerService } from "../Components/spinner.service";
import Footer from "../Components/footer";

class SignatureUpload extends Component {
  state = { files: [], errorMessage: "", hasFile: false };

  uploadFiles = async () => {

    try {
      spinnerService.show("mainSpinner", "Please wait...");

      const [file] = this.state.files;
      const base64 = await this.toBase64(file);
      const response = await mutation(
        "upload_file",
        { image_data: base64, file_name: file.name },
        `image_url status message`,
        "file"
      );

      if (response && response.data && response.data.upload_file && response.data.upload_file.status) {

        let reqParam = {
          signature_image: response.data.upload_file.image_url
        };
        await updateSignApplication(reqParam);
        spinnerService.hide("mainSpinner");

        renderRedirect(this.props, "/selfie");
      } else {
        spinnerService.hide("mainSpinner");

        this.setState({ errorMessage: response.data.upload_file.message });
      }
    } catch (e) {
      this.setState({ errorMessage: e.message });
      console.log(e);
    }
  };

  cancelUpload = async() => {
    this.setState({ hasFile: false });
    this.setState({ files: [] });
  }

  toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  updateUploadedFiles = (files) => {
    if (typeof files === "string") {
      this.setState({ hasFile: false });
      this.setState({ errorMessage: files });
    } else {
      this.setState({ hasFile: true });
      this.setState({ files, errorMessage: "" });
    }
  };

  renderRedirect = () => {
    renderRedirect(this.props, "/bank");
  };
  render() {
    return (      <div className="col-md-6 col-lg-5 col-xl-4">

      <div className="container my-auto py-4 bg-white shadow-lg rounded">
        <div className="row mt-2">
          <div className="col-11 col-lg-11 mx-auto">
            <h1>Signature of yours</h1>

            <div className="contentArea">
              <p className="text-2 text-muted">
              Please sign on a blank paper with a
pen & upload a photo of the same
              </p>
            </div>

            <form id="registerForm" className="form-border" method="post">
              {this.state.errorMessage && (
                <div
                  style={{ color: "red", fontSize: "13px" }}
                  className="errorMessage"
                >
                  {this.state.errorMessage}
                </div>
              )}
              {/* <div class="upload-cancelled-check"> */}
              {/* + Upload cancelled cheque */}
              <FileUpload
                accept=".jpg,.png,.jpeg,.pdf"
                updateFilesCb={this.updateUploadedFiles}
              />
               <div>
                <p className="alert alert-warning alert-dismissible fade show text-center text-1">Kindly do not upload files over 5mb.</p>
              </div>
              {/* </div> */}

              { this.state.hasFile && (

              <div className="btn-group btn-block bank-details-form">
                <button
                  className="btn btn-secondary btn-block text-uppercase mt-4"
                  style={{
                    backgroundColor: "lightgray",
                    border: "none",
                    color: "#00000070",
                    width: "50%",
                  }}
                  type="button"
                  onClick={this.cancelUpload}
                >
                  CANCEL
                </button>
                <button
                  className="btn btn-primary btn-block text-uppercase mt-4"
                  style={{
                    width: "50%",
                  }}
                  type="button"
                  onClick={this.uploadFiles}
                >
                  CONFIRM
                </button>
              </div>

              )}

              {/* <button className="btn btn-primary btn-block text-uppercase mt-4" type="button" onClick={this.renderRedirect}>NEXT</button> */}
            </form>
          </div>
        </div>
      </div><Footer /></div>
    );
  }
}
export default withRouter(SignatureUpload);