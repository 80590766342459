import React, { Component } from 'react';
import { withRouter } from "react-router-dom";

import {renderRedirect} from "../util"
import Footer from "../Components/footer";


 
class ESignNow extends Component {
  renderRedirect = () => {
    renderRedirect(this.props, "/complete");
  }
    render() {
        return (      <div className="col-md-6 col-lg-5 col-xl-4">

          <div  className="container my-auto py-4 bg-white shadow-lg rounded">
          <div className="row mt-2">
            <div className="col-11 col-lg-11 mx-auto">
              <h1>Review</h1>

              <div className="contentArea">
                  <p className="text-2 text-muted">Review TRDR Account Opening Form with details filled in.

</p>
                </div>
              
              <form id="registerForm" className="form-border" method="post">

                <div class="upload-cancelled-check">
                </div>
               
                <button className="btn btn-primary btn-block text-uppercase mt-4" type="button" onClick={this.renderRedirect}>eSIGN NOW</button>
              </form>

            </div>
          </div>
        </div><Footer />
        </div>

            )
    }
}
export default withRouter(ESignNow);