import { mutation, query} from "../../api"


export async function createRazorpayOrder(amount, currency, receipt){
  return mutation("create_razorpay_order", {amount, currency, receipt}, `status
  razorpay_order_id
  message`, "member");
}



export async function pennyDrop(beneficiary_account_no, beneficiary_ifsc){
 
  return query("penny_drop", {beneficiary_account_no, beneficiary_ifsc}, `
  penny_drop_status
  name_match_status`, "digio");

}
export async function faceMatch(selfie_image){
 
  return query("face_match", {selfie_image}, `status
  message
  confidence`, "digio");

}

export async function generateDocument(){
  return query("generate_agreement_document", {}, `id
  customer_identifier
  is_agreement
  agreement_type
  agreement_status
  file_name
  created_at
  self_signed
  self_sign_type
  no_of_pages
  access_token {
    created_at
    id
    entity_id
    valid_till
  }`, "digio");
}
