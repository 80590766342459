import React from "react";
import "./CollapseCard.css";

const CollapseCard = ({ title, content, active, onClick }) => {
    return (
        <div className="accordion-item">
            <div className="accordion-title" onClick={onClick}>
                <div>{title}</div>
                <div>{active ? "-" : "+"}</div>
            </div>
            {active && <div className="accordion-content">{content}</div>}
        </div>
    );
};

export default CollapseCard;
