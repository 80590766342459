import React, { Component } from 'react';
 
import { withRouter } from "react-router-dom";
import {renderRedirect} from "../util";
import Footer from "../Components/footer";

class Awesome extends Component {


  constructor(props) {
    super(props);
    this.state = {
      isValidated: false
    };
  }

  handleChange = (event) => {
    this.setState({isValidated: event.target.checked});
  }

  renderRedirect = () => {
    renderRedirect(this.props, "/kyc");
  }
 
    render() {
        return (      <div className="col-md-6 col-lg-5 col-xl-4">

          <div className="container my-auto py-4 bg-white shadow-lg rounded">
          <div className="row mt-2">




            <div className="col-11 col-lg-11 mx-auto">
              <h1>Awesome!</h1>
              <p className="text-muted text-2 text-left"><b>Let us set up your account.</b></p>
              <p className="text-muted text-2 text-left">Which documents soft copy should I keep ready for uploading?</p>

              <ul className="list-group">
  <li className="list-group-item">
    <b>Signature</b>
    <p className="text-1">Your signature on a blank paper to 
embed in the application form</p>
  </li>
  <li className="list-group-item"><b>PAN Card</b></li>
  <li className="list-group-item"><b>Cancelled Cheque or Bank A/C Statement</b>
  <p className="text-1">We need a Savings account cancelled cheque as per SEBI guidelines</p>
</li>
  
</ul>    <br></br><div className="form-group icon-group">
                    <input className="form-check-input phone-input" type="checkbox" value="" id="flexCheckChecked"  onChange={this.handleChange}  />
                    <label className="text-2"  className="form-check-label text-muted accept-input" for="flexCheckChecked">
                   
                      I authorise TRDR to conduct / update / fetch my KYC as required by regulations.


                    </label>
                  </div>

              <form id="otp-screen" className="form-border" method="post">
                
                <button                 disabled={!this.state.isValidated}
 onClick={this.renderRedirect} className="btn btn-primary btn-block my-3" type="button">NEXT</button>
              </form>
            </div>

          </div>
        </div><Footer />
        </div>
            )
    }
}
export default withRouter(Awesome);