import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { renderRedirect } from "../../util";
import { addReferralCode, verifyOTP } from "./api";
import $ from "jquery";
import { spinnerService } from "../../Components/spinner.service";
import Footer from "../../Components/footer";

class VerifyMobileOTP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobile: localStorage.getItem("mobile"),
      opt1: "",
      otp2: "",
      otp3: "",
      otp4: "",
      formErrors: { otp1: "", otp2: "", otp3: "", otp4: "" },
      otpValid: false,
      errorMessage: "",
    };
  }

  async componentDidMount() {
    $("#otp-screen .form-control").keyup(function () {
      if (this.value.length == 0) {
        $(this).blur().parent().prev().children(".form-control").focus();
      } else if (this.value.length == this.maxLength) {
        $(this).blur().parent().next().children(".form-control").focus();
      }
    });

    this.autoFocus();
  }

  autoFocus = () => {
    $("#first").focus();
  };

  _renderRedirect = () => {
    renderRedirect(this.props, "/email");
  };

  _verifyOTP = async () => {
    spinnerService.show("mainSpinner", "Please wait...");

    let otpValue =
      this.state.otp1 + this.state.otp2 + this.state.otp3 + this.state.otp4;

    let otp = await verifyOTP(
      localStorage.getItem("mobile"),
      localStorage.getItem("session_id"),
      otpValue
    ).catch((errors) => {
      spinnerService.hide("mainSpinner");

      this.setState({ errorMessage: "Error in verifying OTP" });
    });

    if (otp && otp.data && otp.data.verify_session_otp.status) {
      localStorage.setItem("token", otp.data.verify_session_otp.token);
      spinnerService.hide("mainSpinner");

      //for adding referral code if any
      if (localStorage.getItem("referral")) {
        let res = await addReferralCode(
          localStorage.getItem("referral")
        ).catch((errors) => {
          spinnerService.hide("mainSpinner");
    
          console.log("add referral api response", res);
        });
      }
      //for adding referral code if any

      this._renderRedirect();
    } else if (otp && !otp.data.verify_session_otp.status) {
      spinnerService.hide("mainSpinner");

      this.setState({ errorMessage: otp.data.verify_session_otp.message });
    }
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let otpValid = this.state.otpValid;

    switch (fieldName) {
      case "otp1":
        otpValid = value.length == 1;
        fieldValidationErrors.otp1 = otpValid ? "" : " is invalid";
        break;
      case "otp2":
        otpValid = value.length == 1;
        fieldValidationErrors.otp2 = otpValid ? "" : " is invalid";
        break;
      case "otp3":
        otpValid = value.length == 1;
        fieldValidationErrors.otp3 = otpValid ? "" : " is invalid";
        break;
      case "otp4":
        otpValid = value.length == 1;
        fieldValidationErrors.otp4 = otpValid ? "" : " is invalid";
        break;
      default:
        break;
    }
    this.setState(
      { formErrors: fieldValidationErrors, otpValid: otpValid },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({ formValid: this.state.otpValid });
  }

  errorClass(error) {
    return error.length === 0 ? "" : "is-invalid";
  }

  handleUserInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  }

  render() {
    return (
      <div className="col-md-6 col-lg-5 col-xl-4">
        <div className="container my-auto py-4 bg-white shadow-lg rounded">
          <div className="row mt-2">
            <div className="col-11 col-lg-11 mx-auto">
              <h1>Confirm OTP</h1>
              <p className="text-center">
                <img
                  className="img-fluid"
                  src="../images/otp-icon.png"
                  alt="verification"
                />
              </p>
              <p className="text-muted text-2 text-center">
                Please enter the OTP (one time password) to verify your account.
                A Code has been sent to{" "}
                <span className="text-dark text-4">
                  +91 {this.state.mobile}
                </span>
              </p>

              {this.state.errorMessage ? (
                <span
                  style={{ display: "block" }}
                  className="alert alert-warning alert-dismissible fade show"
                >
                  {this.state.errorMessage}
                </span>
              ) : (
                ""
              )}

              <div id="otp-screen" className="form-border" method="post">
                <div className="form-row">
                  <div className="col form-group">
                    <input
                      id="first"
                      inputmode="decimal"
                      pattern="[0-9]*"
                      className="form-control text-center text-6 px-0 py-2"
                      name="otp1"
                      onChange={(event) => this.handleUserInput(event)}
                      maxLength="1"
                      required
                      autoComplete="off"
                    />
                  </div>
                  <div className="col form-group">
                    <input
                      inputmode="decimal"
                      pattern="[0-9]*"
                      className="form-control text-center text-6 px-0 py-2"
                      name="otp2"
                      onChange={(event) => this.handleUserInput(event)}
                      maxLength="1"
                      required
                      autoComplete="off"
                    />
                  </div>
                  <div className="col form-group">
                    <input
                      inputmode="decimal"
                      pattern="[0-9]*"
                      className="form-control text-center text-6 px-0 py-2"
                      name="otp3"
                      onChange={(event) => this.handleUserInput(event)}
                      maxLength="1"
                      required
                      autoComplete="off"
                    />
                  </div>
                  <div className="col form-group">
                    <input
                      inputmode="decimal"
                      pattern="[0-9]*"
                      className="form-control text-center text-6 px-0 py-2"
                      name="otp4"
                      onChange={(event) => this.handleUserInput(event)}
                      maxLength="1"
                      required
                      autoComplete="off"
                    />
                  </div>
                </div>
                <button
                  disabled={!this.state.formValid}
                  onClick={this._verifyOTP}
                  className="btn btn-primary btn-block my-3"
                  type="button"
                >
                  CONFIRM
                </button>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default withRouter(VerifyMobileOTP);
