import React, { Component } from "react";

import { withRouter } from "react-router-dom";
import { renderRedirect } from "../../util";
import { setDigioOption } from "../../util/digio";
import { createKYCRequest, fetchDigilockerData, deletRecords } from "./api";
import Footer from "../../Components/footer";
const configApp = require("../../config");

class VerifyKYC extends Component {
  renderRedirect = () => {
    renderRedirect(this.props, "/pan");
  };

  fetchDigilockerData = async () => {
    await fetchDigilockerData();
    this.renderRedirect();
  };

  async componentDidMount() {
    //await deletRecords();
  }

  callDigio = async () => {
    let kycRequest = await createKYCRequest();
    if (kycRequest && kycRequest.data && kycRequest.data.create_kyc_request) {
      let kycData = kycRequest.data.create_kyc_request;
      let options = setDigioOption(
        configApp.REACT_APP_DIGIO_ENV,
        "https://uat.trdr.in/images/trdr-horizontal.png",
        "#000000",
        "#FFFFFF",
        this.fetchDigilockerData
      );
      try {
        console.log({ kycData });
        console.log({ options });
        let digio = new window.Digio(options);
        digio.init();
        digio.submit(
          kycData.id,
          kycData.customer_identifier,
          kycData.access_token.id
        );
      } catch (e) {
        console.log("digio submit", e);
      }
    }
  };

  render() {
    return (
      <div className="col-md-6 col-lg-5 col-xl-4">
        <div className="container my-auto py-4 bg-white shadow-lg rounded">
          <div className="row mt-2">
            <div className="col-11 col-lg-11 mx-auto">
              <h1>KYC Details</h1>

              <p>
                <b>What you need to keep handy:</b>{" "}
              </p>
              <p className="text-2">
                <ul className="kyc-points">
                  <li>
                    <p>
                      <span className="badge badge-secondary text-3">
                        PAN and Aadhaar number
                      </span>{" "}
                      , mobile number linked to your Aadhaar (as you will get an
                      OTP to confirm).
                    </p>
                    <p>
                      You will be asked to enter these as part of Address
                      verification via DigiLocker.
                    </p>
                  </li>
                </ul>
              </p>
              <p>
                <b>SEBI guidelines requires us to collect your geo-location.</b>
              </p>
              <p className="alert alert-warning alert-dismissible fade show text-left text-1">
                <p>Allow your web browser to access location.</p>{" "}
                <p>
                  If prompted to allow location access, please allow access to
                  complete the application process.
                </p>{" "}
              </p>

              <form id="otp-screen" className="form-border" method="post">
                <button
                  onClick={this.callDigio}
                  className="btn btn-primary btn-block my-3"
                  type="button"
                >
                  START YOUR KYC
                </button>
              </form>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default withRouter(VerifyKYC);
