import { query, mutation } from "../../api";
import axios from "axios";

export async function updateSignApplication(reqObj) {
  try {
    let basicProfile =  await mutation(
      "update_signup_application",
      reqObj,
      `_id
        status
        message
        fullName
        lastName
        businessName
        emailAddress
        gender
        pan
        martialStatus
        residentialStatus
        permanentAddress
        communicationAddress
        fathersName
        mothersName
        occupationType
        annualIncome
        isPoliticalyExposed,
        referralCode`
    );

    return basicProfile;
    
  } catch (e) {
    console.log(e);
  }
}

export async function fetchSignApplication(){
    return mutation("fetch_signup_application", {}, `_id
    status
    message
    user_id
    fullName
    firstName
    lastName
    businessName
    emailAddress
    gender
    dob
    pan
    martialStatus
    residentialStatus
    permanentAddress
    communicationAddress
    fathersName
    mothersName
    occupationType
    annualIncome
    isPoliticalyExposed
    pan_front
    cheque_image
    signature_image
    profile_image
    razorpay_order_id
    account_number
    ifsc
    account_type
    net_worth
    net_worth_date
    referralCode`
  );
}


export async function sendEmail(reqObj) {
  return mutation(
    "send_email",
    {
      to: reqObj.emailAddress
    },
    "status",
    "email"
  );
}

export async function verifyEmail(email, otp) {
  return mutation(
    "verify_email",
    {
      email_id: email, otp
    },
    `status
    message`,"email"
  );
}


export async function getIfscCode(ifscCode) {
  try {    
    let url= 'https://ifsc.razorpay.com/'+ifscCode;
    const response = await axios.get(url);
    return response.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
}