import React, { Component } from 'react';
import { withRouter } from "react-router-dom";

import {renderRedirect} from "../util"
import { updateSignApplication } from "../Pages/BasicProfile/api";
import { spinnerService } from "../Components/spinner.service";
import Footer from "../Components/footer";


 
class GeoLocation extends Component {
  state = { errorMessage: "", successMessage:"", latitude: "", longitude: "" };

  async componentDidMount() {
  }
  renderRedirect = () => {
    renderRedirect(this.props, "/review");
  }
  error = (err) => {
    spinnerService.hide("mainSpinner");
    this.setState({ errorMessage: "Geolocation is not supported by this browser. Please enable." });
    this.setState({ successMessage: "" });
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }
  shareLocation = () => {
    if (navigator.geolocation) {
      spinnerService.show("mainSpinner", "Please wait...");

      navigator.geolocation.getCurrentPosition(this.showPosition,

        this.error,  {
          timeout: 10000,
          maximumAge: 10000,
          enableHighAccuracy: true
        });
    } else {
      spinnerService.hide("mainSpinner");
      this.setState({ errorMessage: "Geolocation is not supported by this browser." });
      this.setState({ successMessage: "" });
    }
  }
  showPosition = async (position)  => {
    spinnerService.hide("mainSpinner");

    this.setState({ successMessage: "Your device's location was captured." });
    this.setState({ errorMessage: "" });

    this.setState({ latitude: position.coords.latitude });
    this.setState({ longitude: position.coords.longitude });
    let reqParam = {
      latitude: position.coords.latitude,
      longitude: position.coords.longitude
    };
    console.log(reqParam);
    let updateProfile = await updateSignApplication(reqParam);

    // if (updateProfile) {
    //   this._renderRedirect();
    // }
  }
    render() {
        return (      <div className="col-md-6 col-lg-5 col-xl-4">

          <div  className="container my-auto py-4 bg-white shadow-lg rounded">
          <div className="row mt-2">
            <div className="col-11 col-lg-11 mx-auto">
              <h1>Share Location</h1>

              <div className="contentArea">


<p>As per the new SEBI guidelines, 
we need to capture your location 
at the time of capturing your 
photo.</p> 
<p className="text-2">Please allow this device to share 
location with TRDR for the 
account opening process.</p>

<p className="alert alert-warning alert-dismissible fade show text-left text-1">
  Note: Do check if the browser has 
  blocked location access.
</p>



                 
                </div>
              
                <form id="registerForm" className="form-border" method="post">

                    {this.state.errorMessage ? (
                    <span style={{ display: "block" }} className="alert alert-warning alert-dismissible fade show text-left">
                      {this.state.errorMessage}
                    </span>
                    ) : (
                    ""
                    )}

                    {this.state.successMessage ? (
                    <span style={{ display: "block" }} className="alert alert-success alert-dismissible fade show text-left">
                      {this.state.successMessage}
                    </span>
                    ) : (
                    ""
                    )}
               
                  {this.state.successMessage == "" && (
                  <button className="btn btn-primary btn-block text-uppercase mt-4" type="button" onClick={this.shareLocation} >Share</button>
                  )}
                
                {this.state.successMessage != "" && (
                <button className="btn btn-primary btn-block text-uppercase mt-4" type="button" onClick={this.renderRedirect}>NEXT</button>
                )}
              </form>

            </div>
          </div>
        </div><Footer />
        </div>

            )
    }
}
export default withRouter(GeoLocation);