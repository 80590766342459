import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { renderRedirect } from "../util";
import FileUpload from "../Components/file-upload.component";
import { mutation } from "../api";
import { updateSignApplication } from "./BasicProfile/api";
import { spinnerService } from "../Components/spinner.service";
import Footer from "../Components/footer";
import {faceMatch} from "./Pay/api"

class PhotoCapture extends Component {
  state = { files: [], errorMessage: "",hasStarted: false, isPhotoMatchSuccess: true };
 
  async componentDidMount() {
  
    let camera_button = document.querySelector("#start-camera");
    let video = document.querySelector("#video");
    let click_button = document.querySelector("#click-photo");
    let canvas = document.querySelector("#canvas");
    let dataurl = document.querySelector("#dataurl");
    let dataurl_container = document.querySelector("#dataurl-container");
    let retake_confirm_block = document.querySelector("#retake_confirm_block");
    retake_confirm_block.style.display = 'none';


      camera_button.addEventListener('click', async function() {
        let stream = null;
        try {
          window.stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: false });
        }
        catch(error) {
          alert(error.message);
          return;
        }    
        video.srcObject = window.stream;
        video.style.display = 'block';
        camera_button.style.display = 'none';
        click_button.style.display = 'block';
    });
    
    click_button.addEventListener('click', function() {
      var currentdate = new Date();
      var datetime =  currentdate.getDate() + "/" + (parseInt(currentdate.getMonth()) + 1)
      + "/" + currentdate.getFullYear() + " @ " 
      + currentdate.getHours() + ":" 
      + currentdate.getMinutes() + ":" + currentdate.getSeconds();

        var ctx = canvas.getContext("2d");
        ctx.fillStyle = "black";
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        ctx.font = "12px Arial";
        ctx.fillText(datetime, 10, 20);
        
        let image_data_url = canvas.toDataURL('image/jpeg');
        
        video.style.display = 'none';
        dataurl.value = image_data_url;
        dataurl_container.style.display = 'block';


        let retake_confirm_block = document.querySelector("#retake_confirm_block");
        let capture_block = document.querySelector("#capture_block");
        retake_confirm_block.style.display = '-webkit-box';
        capture_block.style.display = 'block';
        let click_button = document.querySelector("#click-photo");

        click_button.style.display = 'none';

    });
  }

  retake = () =>{
      let click_button = document.querySelector("#click-photo");
      click_button.style.display = 'block';

      let video = document.querySelector("#video");
      video.style.display = 'block';

      let dataurl_container = document.querySelector("#dataurl-container");
      dataurl_container.style.display = 'none';

      let retake_confirm_block = document.querySelector("#retake_confirm_block");
      retake_confirm_block.style.display = 'none';

  }

  uploadFiles = async () => {
    try {
      let video = document.querySelector("#video");
      let dataurl = document.querySelector("#dataurl");
      let image_data_url = dataurl.value;
      const base64 = image_data_url;
      spinnerService.show("mainSpinner", "Please wait...");


      let faceMatchResponse = await faceMatch(base64).catch(res=>{
          this.isPhotoMatchSuccess = false;
          spinnerService.hide("mainSpinner");
          return;
      });
      if(faceMatchResponse?.data?.face_match){
        if(faceMatchResponse.data.face_match.message != "matched"){
          this.isPhotoMatchSuccess = false;
          spinnerService.hide("mainSpinner");
          return;
        }
      }

      const response = await mutation(
        "upload_file",
        { image_data: base64, file_name: "profile" },
        `image_url status message`,
        "file"
      );

      if (response && response.data && response.data.upload_file && response.data.upload_file.status) {

        let reqParam = {
          profile_image: response.data.upload_file.image_url
        };
        await updateSignApplication(reqParam);
       
        window.stream.getTracks().forEach(function(track) {
          track.stop();
        });
        spinnerService.hide("mainSpinner");
        renderRedirect(this.props, "/location");
      } else {
        this.setState({ errorMessage: response.data.upload_file.message });
      }
    } catch (e) {
      this.setState({ errorMessage: e.message });
      console.log(e);
    }
  };

  toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
  });

  updateUploadedFiles = (files) => {
    if (typeof files === "string") {
      this.setState({ errorMessage: files });
    } else {
      this.setState({ files, errorMessage: "" });
    }
  };

  renderRedirect = () => {
    renderRedirect(this.props, "/bank");
  };
  render() {
    return (      <div className="col-md-6 col-lg-5 col-xl-4">

      <div className="container my-auto py-4 bg-white shadow-lg rounded">
        <div className="row mt-2">
          <div className="col-11 col-lg-11 mx-auto">
            <h1>Capture a Selfie</h1><div className="contentArea">
              <p className="text-2 text-muted">Capture a clear selfie that will be
used for your records</p>
            </div><form id="registerForm" className="form-border" method="post">
              {this.state.errorMessage && (
                <div
                  style={{ color: "red", fontSize: "13px" }}
                  className="errorMessage"
                >
                  {this.state.errorMessage}
                </div>
              )}
              {/* <div class="upload-cancelled-check"> */}
              {/* + Upload cancelled cheque */}
              <video id="video"  height="240" autoPlay></video>
              <div id="dataurl-container">
              <canvas id="canvas" width="320" height="240"></canvas>
              <textarea id="dataurl" readonly></textarea>
              </div>
            
              {/* </div> */}
           
              {this.state.isPhotoMatchSuccess === false  &&
              <div>
                <p className="alert alert-warning alert-dismissible fade show text-center text-1">Photo match failed with Aadhar, Please retake the photo.</p>
              </div>

              }

             <button  type="button" className="btn btn-primary btn-block text-uppercase mt-4"  id="click-photo">
              
              <i class="fa fa-camera" aria-hidden="true"></i> &nbsp;
               Click Photo
              </button>


             <div id="capture_block" className="btn-group btn-block bank-details-form">
                <button
                  id="start-camera"
                  type="button"
                  className="btn btn-primary btn-block text-uppercase mt-4"
                  style={{
                    width: "50%",
                  }}>
                  CAPTURE A SELFIE
                </button>
              </div>
              

           
              <div id="retake_confirm_block" className="btn-group btn-block bank-details-form">
                <button
                  className="btn btn-secondary btn-block text-uppercase mt-4"
                  style={{
                    backgroundColor: "lightgray",
                    border: "none",
                    color: "#00000070",
                    width: "50%",
                  }}
                  type="button"
                  onClick={this.retake}
                >
                  RETAKE
                </button>
                <button
                  className="btn btn-primary btn-block text-uppercase mt-4"
                  style={{
                    width: "50%",
                  }}
                  type="button"
                  onClick={this.uploadFiles}
                >
                  CONFIRM
                </button>
              </div>
            
              {/* <button className="btn btn-primary btn-block text-uppercase mt-4" type="button" onClick={this.renderRedirect}>NEXT</button> */}
            </form>
          </div>
        </div>
      </div><Footer /></div>
    );
  }
}
export default withRouter(PhotoCapture);