import {query, mutation} from "../../api"
  
export async function createKYCRequest(){
    return mutation("create_kyc_request", {}, `id
    created_at
    status
    customer_identifier
    reference_id
    access_token {
      created_at
      id
      entity_id
      valid_till
    }`, "digio");
}


export async function fetchDigilockerData(){
  return query('fetch_digilocker_data', {}, `status`, "digio");
}


export async function deletRecords(){
  return mutation("delete_user_info", {}, `status
  message`, "member");
}

export async function createRazorpayOrder(){
  return mutation("create_razorpay_order", {}, `status
  razorpay_order_id
  message`, "member");
}
