import { workFlowStepUpdate } from "../util/login";

export function renderRedirect(props, url) {
  console.log("renderRedirectUrl", url);
  if (
    localStorage.getItem("identifierName") !== "mobile_verification" &&
    localStorage.getItem("identifierName") !== "email_verification"
  ) {
    workFlowStepUpdate(localStorage.getItem("identifier"));
  }
  props.history.push(url);
}

export function workFlowSteps() {
  let workflow_steps = [
    {
      name: "mobile_verification",
    },
    {
      name: "email_verification",
      redirect: "email",
    },
    {
      name: "account_fee_payment",
      redirect: "fees",
    },
    {
      name: "digilocker_aadhaar_pan_geo_location",
      redirect: "kyc",
    },
    {
      name: "pan_upload",
      redirect: "pan",
    },
    {
      name: "personal_details",
      redirect: "personal",
    },
    {
      name: "address_details",
      redirect: "address",
    },
    {
      name: "additional_details",
      redirect: "additional",
    },
    {
      name: "cheque_bank_statement_upload",
      redirect: "bank-cheque",
    },
    {
      name: "bank_details",
      redirect: "bank",
    },
    {
      name: "signature_upload",
      redirect: "signature",
    },
    {
      name: "ipv_selfie",
      redirect: "pan",
    },
    {
      name: "ipv_Video",
      redirect: "selfie",
    },
    {
      name: "agreement_doc_generate_and_sign",
      redirect: "review",
    },
    {
      name: "email_with_appliction_confirmation_agreement",
      redirect: "pan",
    },
    {
      name: "email_once_account_set",
      redirect: "email-otp",
    },
  ];

  return workflow_steps;
}
