import logo from "./logo.svg";
import "./App.css";
import {
  BrowserRouter,
  BrowserRouter as Router,
  Route,
  Switch
  
} from "react-router-dom";
import { GuardProvider, GuardedRoute } from "react-router-guards";
import VerifyMobileOTP from "./Pages/Mobile/VerifyMobileOTP";
import VerifyEmailOTP from "./Pages/BasicProfile/VerifyEmailOTP";
import MobileOTP from "./Pages/Mobile/MobileOTP";
import Complete from "./Pages/Complete";
import BasicProfile from "./Pages/BasicProfile/BasicProfile";
import PayFees from "./Pages/Pay/PayFees";
import Awesome from "./Pages/Awesome";
import VerifyKYC from "./Pages/KYC/VerifyKYC";
import Header from "./Components/header";
import MenuBar from "./Components/menu-bar";
import StaticContent from "./Components/static-content";
import PersonalDetails from "./Pages/PersonalDetails";
import AddressDetails from "./Pages/AddressDetails";
import AdditionalDetails from "./Pages/AdditionalDetails";
import LinkBankAccount from "./Pages/LinkBankAccount";
import BankDetails from "./Pages/BankDetails";
import ReviewDocument from "./Pages/ReviewDocument";
import ESignNow from "./Pages/ESignNow";
import { requireLogin } from "./util/login";
import { Spinner } from "./Components/spinner";
import PhotoCapture from "./Pages/PhotoCapture";
import PanCardUpload from "./Pages/PanCardUpload";
import SignatureUpload from "./Pages/SignatureUpload";
import GeoLocation from "./Pages/GeoLocation";
import MainPage from "./Pages/MainPage";
import React, { Component } from 'react';
import { createBrowserHistory } from "history";
import AddNominee from "./Pages/AddNominee";

class App extends Component{

  constructor(props) {
    super(props);
    this.state = {
    isHome: false
    };
  }

  componentWillMount() {
    const { history } = this.props;
    this.unsubscribeFromHistory = history.listen(this.handleLocationChange);
    this.handleLocationChange(history.location);
  }

  componentWillUnmount() {
    if (this.unsubscribeFromHistory) this.unsubscribeFromHistory();
  }

  handleLocationChange = (location) => {
    this.setState({isHome:  location.pathname.toLowerCase() == "/signup" || location.pathname.toLowerCase() == "/" ? true : false});
  }

  render(){
    return (
      <div className="wrapper">
        <Spinner name="mainSpinner">
          <div className="lds-ellipsis-spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </Spinner>
        <div className="preloader">
          <div className="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div id="main-wrapper" className="oxyy-login-register">
          <div className="hero-wrap d-flex align-items-center h-100">
            <div className="hero-mask bg-primary"></div>
            <div className="hero-content mx-auto w-100 min-vh-100 d-flex flex-column">
              <div className="container pt-2 d-none d-sm-block">
             
              
                {this.state.isHome && (
                <MenuBar>
                  
                </MenuBar>
                )}
            
           
           
              </div>
              <div className="container my-auto py-1">
                <div className="row">
                  <StaticContent></StaticContent>
                  
                       
                     
                      <GuardProvider   guards={[requireLogin]}>
                        <Switch>
                          <GuardedRoute exact path="/" component={MainPage} />
                          <GuardedRoute exact path="/mobile" component={MobileOTP} />
  
                          <GuardedRoute
                            exact
                            path="/mobile-otp"
                            component={VerifyMobileOTP}
                          
                          />
                          <GuardedRoute
                            exact
                            path="/email-otp"
                            component={VerifyEmailOTP}
                            meta={{ auth: true }}
  
                          />
                          <GuardedRoute
                            exact
                            path="/email"
                            component={BasicProfile}
                            meta={{ auth: true }}
                          />
                          <GuardedRoute
                            exact
                            path="/fees"
                            component={PayFees}
                            meta={{ auth: true }}
  
                          />
                          <GuardedRoute
                            exact
                            path="/awesome"
                            component={Awesome}
                            meta={{ auth: true }}
  
                          />
                          <GuardedRoute
                            exact
                            path="/kyc"
                            component={VerifyKYC}
                            meta={{ auth: true }}
  
                          />
                          <GuardedRoute
                            exact
                            path="/personal"
                            component={PersonalDetails}
                            meta={{ auth: true }}
  
                          />
                          <GuardedRoute
                            exact
                            path="/address"
                            component={AddressDetails}
                            meta={{ auth: true }}
  
                          />
                          <GuardedRoute
                            exact
                            path="/additional"
                            component={AdditionalDetails}
                            meta={{ auth: true }}
  
                          />
                          <GuardedRoute
                            exact
                            path="/bank-cheque"
                            component={LinkBankAccount}
                            meta={{ auth: true }}
  
                          />
                          <GuardedRoute
                            exact
                            path="/bank"
                            component={BankDetails}
                            meta={{ auth: true }}
  
                          />
                          <GuardedRoute
                            exact
                            path="/review"
                            component={ReviewDocument}
                            meta={{ auth: true }}
  
                          />
                          <GuardedRoute
                            exact
                            path="/sign"
                            component={ESignNow}
                            meta={{ auth: true }}
  
                          />
                          <GuardedRoute
                            exact
                            path="/complete"
                            component={Complete}
                            meta={{ auth: true }}
  
                          />
                          <GuardedRoute
                            exact
                            path="/selfie"
                            component={PhotoCapture}
                            meta={{ auth: true }}
  
                          />
                          <GuardedRoute
                            exact
                            path="/pan"
                            component={PanCardUpload}
                            meta={{ auth: true }}
  
                          />
                          <GuardedRoute
                            exact
                            path="/signature"
                            component={SignatureUpload}
                            meta={{ auth: true }}
  
                          />
                          <GuardedRoute
                            exact
                            path="/location"
                            component={GeoLocation}
                            meta={{ auth: true }}
  
                          />
                          <GuardedRoute
                            exact
                            path="/add-nominee"
                            component={AddNominee}
                            meta={{ auth: true }}  
                          />
                        </Switch>
                      </GuardProvider>
                  
                 
                  
                </div>
              </div>
            </div>
          </div>
         
        </div>
      </div>
    );
  }
  


}
export default App;
